import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../context/LanguageContext';
import translations from '../../components/translation.js';
import DatePicker from "react-datepicker";
import DateTime from "react-datetime";
import "react-datepicker/dist/react-datepicker.css";
import AdminHeader from '../../components/AdminHeader/AdminHeader.js'
import Footer from '../../components/AdminFooter/AdminFooter.js'
import calendar from '../../images/calender.png'

const UploadPicture = () => {
  const navigate = useNavigate();

    const { language } = useLanguage();
    const t = translations[language];
    const [date, setDate] = useState("");
    const [categories, setCategories] = useState([]);
    const [message, setMessage] = useState("");
    const today = new Date();
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/admin"); // Redirect to login page if no token
      }
    }, []);

    const categoryAbbreviations = {
      "Social Welfare": "sw",
      "Education": "ed",
      "Rural Development": "rd",
      "Youth Development": "yd",
      "Women Empowerment": "we"
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleFileChange = (e) => {
      const selectedFiles = Array.from(e.target.files);
      const validFiles = selectedFiles.filter(file => file.type.startsWith("image/"));
      
      if (validFiles.length !== selectedFiles.length) {
        setMessage("Please upload only image files.");
        alert("Please upload only image files.");
      } else {
        setMessage(""); // Clear the message
        setFiles(prevFiles => [...prevFiles, ...validFiles]);
      }
    };

    const handleRemoveFile = (fileToRemove) => {
      setFiles(files.filter(file => file !== fileToRemove));
    };
  
    // Handle form field changes
    const handleDateChange = (e) => {
      setDate(e.target.value);
    }

    const handleCategoriesChange = (e, category) => {
      const checked = e.target.checked;
      if (checked) {
        setCategories((prevCategories) => [...prevCategories, category]);
      } else {
        setCategories((prevCategories) =>
            prevCategories.filter((item) => item !== category)
        );
      }
    };
  
    // Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);

      console.log("submitting form ------------->")
    
      if (files.length === 0) {
        setMessage("Please select at least one file.");
        alert("Please select at least one file.");
        return;
      }
    
      // if (categories.length === 0) {
      //   alert("Please select at least one category.");
      //   return;
      // }
    
      // console.log("Form submitted with categories:", categories);
    
      let allUploadedSuccessfully = true; // Track if all files are uploaded successfully
      let message = ""; // Temporary message to construct final feedback

      for (const file of files) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("date", date);
        formData.append("categories", categories);
 
        try {
          const response = await fetch("https://www.meghnabordikar.com/api/uploadpic", {
            method: "POST",
            body: formData,
          });

          const data = await response.json();
          if (response.ok) {
            message += `Image ${file.name} uploaded successfully!\n`;
            console.log(`File: ${file.name} - Uploaded successfully`);
            setDate("");
            setFiles([]);
            setCategories([]);
            setIsLoading(false);
          } else {
            allUploadedSuccessfully = false;
            message += `Failed to upload image ${file.name}.\n`;
            console.error(`File: ${file.name} - Error: ${data.error}`);
          }
        } catch (error) {
          allUploadedSuccessfully = false;
          message += `Error uploading image ${file.name}.\n`;
          console.error(`File: ${file.name} - Network error`);
        }
      }

      // Set the final message after all uploads
      if (allUploadedSuccessfully) {
        setMessage("All files uploaded successfully!");
        alert("All files uploaded successfully!");
      } else {
        setMessage(message);
      }
    };
    
    useEffect(() => {
      if (isLoading) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    
      return () => {
        document.body.style.overflow = "";
      };
    }, [isLoading]);
  
    return (
        <div className="relative">
            <AdminHeader />
            {/* D E S K T O P */}
            <div className="flex xxs:hidden tabPt:flex flex-col w-full pt-[7vw] bg-cbg2 font-poppins text-[1.05vw] relative" style={{ minHeight: `calc(100vh - 30vw)` }}>
              {isLoading && (
                <div className="absolute bottom-[0vw] left-1/2 -translate-x-1/2 z-[22] h-full w-full bg-white bg-opacity-50">
                  <div className="flex flex-col justify-center items-center">
                    <motion.div
                      className="absolute top-[20vw] h-screen"
                      style={{
                        width: "5vw",
                        height: "5vw",
                        border: "10px solid rgba(232, 81, 18, 0.5)", // Outer ring
                        borderTop: "10px solid #E85112", // Highlighted moving part
                        borderRadius: "50%",
                        boxSizing: "border-box",
                      }}
                      animate={{
                        rotate: 360,
                      }}
                      transition={{
                        duration: 1, // Time for one full spin
                        repeat: Infinity, // Infinite loop
                        ease: "linear", // Smooth, consistent motion
                      }}
                    />
                  </div>
                </div>
              )}
              <div className='bg-cbg2 px-[3vw]'>
                <div className='py-[2vw] font-poppinsSemiBold text-[1.2vw] text-start '>
                  {t.gallery_upload_header}
                </div>
                <div className='w-full mb-[2vw] border-b-2 border-[#D0CCCB]'></div>
              </div>
              <form onSubmit={handleSubmit} className="flex flex-row flex-grow bg-white">
                <div className="flex flex-col justify-start items-start w-[30vw] pl-[3vw] pr-[1vw] pt-[1vw] pb-[2vw] gap-[0.5vw]">
                  <div className="w-full px-[1vw] text-[1.5vw] text-start">{t.uploadImg}</div>
                  <div className="flex-grow w-[24vw] max-h-fit flex flex-row justify-between items-center ml-[1vw] mr-[2vw] px-[0.5vw] py-[0.25vw] gap-[0.5vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2">  
                    <label htmlFor="date" className="mr-[1vw]">{t.selectDate}</label>
                    <input
                      className="flex-grow py-[0.25vw] flex flex-row justify-evenly items-center focus:outline-none"
                      type="date"
                      id="date"
                      value={date}
                      onChange={handleDateChange}
                      required
                      pattern="\{4}-\d{2}-\d{2}"
                    />
                  </div>
                  <div className="w-full flex flex-col justify-between items-start px-[1vw] pb-[0.5vw]">
                    <label className="h-[3vw] w-full text-start flex flex-col justify-center items-group-center items-start" htmlFor="categories">{t.selectCat}</label>
                    <div className="w-full py-[0.5vw] pl-[1vw] gap-[1vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2">
                      {[
                        "Social Welfare",
                        "Education",
                        "Rural Development",
                        "Youth Development",
                        "Women Empowerment",
                      ].map((category) => (
                        <label key={category} className="flex items-center justify-start">
                            <input
                            className="mr-[0.5vw]"
                              type="checkbox"
                              value={t[categoryAbbreviations[category]]}  // Using abbreviation value
                              checked={categories.includes(categoryAbbreviations[category])}  // Checking based on abbreviation
                              onChange={(e) => handleCategoriesChange(e, categoryAbbreviations[category])}  // Using abbreviation for change handler
                              // required
                            />
                            <span>{category}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                  <button 
                    className="w-[15vw] py-[0.75vw] mx-[1vw] rounded-[0.2vw] flex justify-center items-center bg-cor text-white" 
                    type="submit" 
                    // onClick={handleSubmit}
                  >
                      {t.upload}
                  </button>
                </div>
                <div className="flex flex-grow p-[1vw] select-none">
                  <div className="grid grid-cols-3 gap-[1vw] w-full py-[2vw]">
                    {files.map((file, index) => (
                      <div key={index} className="relative">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`file-${index}`}
                          className="w-full h-[12.5vw] object-cover rounded-[1vw]"
                        />
                        <button
                           type="button"
                          onClick={() => handleRemoveFile(file)}
                          className="absolute top-[0.5vw] right-[0.5vw] flex justify-center items-center h-[3vw] w-[3vw] bg-black text-white rounded-full cursor-pointer"
                        >
                          ✕
                        </button>
                      </div>
                    ))}
                    {/* Custom Upload Button */}
                    <label
                      title="Click here to upload pictures"
                      htmlFor="file"
                      className="h-[12.5vw] w-full flex justify-center items-center border-2 border-dashed border-cbg5 bg-cbg4 rounded-[1vw] cursor-pointer"
                    >
                      <span className="text-[9.5vw] h-[10vw] w-[10vw] text-white flex justify-center items-center bg-cbg5 rounded-full">+</span>
                    </label>
              
                    {/* Hidden File Input */}
                    <input
                      type="file"
                      id="file"
                      onChange={handleFileChange}
                      multiple
                      accept="image/*"
                      style={{ display: "none" }} // Hides the native file input
                    />
                  </div>
                </div>
              </form>
              {/* {message && <p>{message}</p>} */}
            </div>

            {/* M O B I L E */}
            <div className="flex tabPt:hidden flex-col w-full pt-[7vw] bg-cbg2 font-poppins text-[4vw]">
              {isLoading && (
                <div className="absolute bottom-[0vw] left-1/2 -translate-x-1/2 z-[22] h-full w-full bg-white bg-opacity-50">
                  <div className="flex flex-col justify-center items-center">
                    <motion.div
                      className="absolute top-[50vh] h-screen"
                      style={{
                        width: "10vw",
                        height: "10vw",
                        border: "10px solid rgba(232, 81, 18, 0.5)", // Outer ring
                        borderTop: "10px solid #E85112", // Highlighted moving part
                        borderRadius: "50%",
                        boxSizing: "border-box",
                      }}
                      animate={{
                        rotate: 360,
                      }}
                      transition={{
                        duration: 1, // Time for one full spin
                        repeat: Infinity, // Infinite loop
                        ease: "linear", // Smooth, consistent motion
                      }}
                    />
                  </div>
                </div>
              )}
              <div className='bg-cbg2 px-[5vw]'>
                <div className='pt-[7.5vw] font-poppinsSemiBold text-[3vw] text-start leading-[7.5vw]'>
                  {t.gallery_upload_header}
                </div>
                <div className='w-full mb-[2vw] border-b-2 border-[#D0CCCB]'></div>
              </div>
              <form onSubmit={handleSubmit} className="flex flex-col bg-white px-[3vw]">
                <div className="flex flex-col justify-start items-start w-full pr-[1vw] pt-[1vw] pb-[2vw] gap-[3vw]">
                  <div className="w-full px-[1vw] text-[4.5vw] text-start font-poppinsMedium">{t.uploadImg}</div>
                  <div className="w-full flex flex-row justify-between items-center px-[2vw] gap-[3vw] relative border border-[#DED5D5] rounded-[0.2vw] shadow-cs2 ">  
                    <label htmlFor="date" className="mr-[1vw] w-[25vw] text-start">{t.selectDate}</label>
                    <input
                      className="flex py-[1.5vw] px-[2vw] w-fit min-w-[30vw] opacity-100 flex-row justify-evenly items-center bg-white focus:outline-none"
                      type="date"
                      id="datemob"
                      value={date}
                      onChange={handleDateChange}
                      required
                      pattern="\{4}-\d{2}-\d{2}"
                      placeholder="dd-mm-yyyy"
                    />
                  </div>
                  <div className="w-full flex flex-col justify-between items-start px-[1vw] pb-[3vw]">
                    <label className="w-full text-start flex flex-col justify-center items-group-center items-start" htmlFor="categories">{t.selectCat}</label>
                    <div className="w-full py-[2vw] pl-[2vw] mt-[2vw] gap-[2vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2">
                      {[
                        "Social Welfare",
                        "Education",
                        "Rural Development",
                        "Youth Development",
                        "Women Empowerment",
                      ].map((category) => (
                        <label key={category} className="flex items-center justify-start">
                          <input
                            className="mr-[2vw]"
                            type="checkbox"
                            value={t[categoryAbbreviations[category]]}  // Using abbreviation value
                            checked={categories.includes(categoryAbbreviations[category])}  // Checking based on abbreviation
                            onChange={(e) => handleCategoriesChange(e, categoryAbbreviations[category])}  // Using abbreviation for change handler
                            // required
                          />
                          <span>{category}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                  <button className="w-[40vw] py-[1vw] mx-[1vw] rounded-[1vw] flex justify-center items-center bg-cor text-white" type="submit">{t.upload}</button>
                </div>
                <div className="flex flex-grow p-[1vw] select-none">
                  <div className="grid grid-cols-2 gap-[3vw] w-full pt-[2vw] pb-[3vw]">
                    {files.map((file, index) => (
                      <div key={index} className="relative">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`file-${index}`}
                          className="w-full h-[30vw] object-cover rounded-[2vw]"
                        />
                        <button
                         type="button"
                          onClick={() => handleRemoveFile(file)}
                          className="absolute top-[2vw] right-[2vw] flex justify-center items-center h-[5vw] w-[5vw] bg-black text-[3vw] text-white rounded-full cursor-pointer"
                        >
                          ✕
                        </button>
                      </div>
                    ))}
                    {/* Custom Upload Button */}
                    <label
                      htmlFor="file"
                      className="h-[30vw] w-full flex justify-center items-center border-2 border-dashed border-cbg5 bg-cbg4 rounded-[2vw] cursor-pointer"
                    >
                      <span className="text-[15vw] h-[20vw] w-[20vw] text-white flex justify-center items-center bg-cbg5 rounded-full">+</span>
                    </label>
              
                    {/* Hidden File Input */}
                    <input
                      type="file"
                      id="file"
                      onChange={handleFileChange}
                      multiple
                      accept="image/*"
                      style={{ display: "none" }} // Hides the native file input
                    />
                  </div>
                </div>
              </form>
              {/* {message && <p>{message}</p>} */}
            </div>
            <Footer />
        </div>
    )
}

export default UploadPicture