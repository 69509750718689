import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../context/LanguageContext';
import { useNavigate } from "react-router-dom";
import translations from '../components/translation.js';
import DatePicker from "react-datepicker";
import DateTime from "react-datetime";
import "react-datepicker/dist/react-datepicker.css";
import AdminHeader from '../components/AdminHeader/AdminHeader.js';
import Footer from '../components/AdminFooter/AdminFooter.js'
import calendar from '../images/calender.png'

const UploadEvent = () => {
  const navigate = useNavigate();

  const { language } = useLanguage();
  const t = translations[language];
  const [date, setDate] = useState("");
  const [message, setMessage] = useState("");
  const [eventName, setEventName] = useState("");
  const [location, setLocation] = useState("");
  const [content, setContent] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const today = new Date();
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin"); // Redirect to login page if no token
    }
  }, []);

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setCoverImage(file);
    } else {
      setMessage("Please upload a valid image file for cover image.");
    }
  };
  
  useEffect(() => {
      window.scrollTo(0, 0);
      // console.log(images)
  }, []);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(file => file.type.startsWith("image/"));
    
    if (validFiles.length !== selectedFiles.length) {
      setMessage("Please upload only image files.");
    } else {
      setMessage(""); // Clear the message
      setFiles(prevFiles => [...prevFiles, ...validFiles]);
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    setFiles(files.filter(file => file !== fileToRemove));
  };
  
  const handleDateChange = (e) => {
    setDate(e.target.value);
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    if (files.length === 0) {
      setMessage("Please select at least one file.");
      return;
    }
    let allUploadedSuccessfully = true; // Track if all files are uploaded successfully
    let message = ""; // Temporary message to construct final feedback
  
    // First API: Upload images without coverImage
    for (const file of files) {
      const formData = new FormData();
      formData.append("name", eventName);
      formData.append("image", file);
      formData.append("date", date);
      formData.append("eventName", eventName);
      formData.append("location", location);
      formData.append("content", content);
  
      // Print the keys and values of the formData
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
  
      try {
        const response = await fetch("https://www.meghnabordikar.com/upload/api/uploadevent", {
          method: "POST",
          body: formData,
        });
  
        const data = await response.json();
        if (response.ok) {
          setMessage("File uploaded succesfully")
          // setMessage((prevMessage) =>
          //   prevMessage
          //     ? `${prevMessage}\nImage ${file.name} uploaded successfully!`
          //     : `Image ${file.name} uploaded successfully!`
          // );
          console.log(`File: ${file.name} - Uploaded successfully`);
          setDate("");
          setEventName("");
          setContent("");
          setLocation("");
          setCoverImage(null);
          setFiles([]);
          setIsLoading(false);
        } else {
          setMessage((prevMessage) =>
            prevMessage
              ? `${prevMessage}\nFailed to upload image ${file.name}.`
              : `Failed to upload image ${file.name}.`
          );
          console.error(`File: ${file.name} - Error: ${data.error}`);
        }
      } catch (error) {
        setMessage((prevMessage) =>
          prevMessage
            ? `${prevMessage}\nError uploading image ${file.name}.`
            : `Error uploading image ${file.name}.`
        );
        console.error(`File: ${file.name} - Network error`);
      }
    }
  
    // Second API: Send name and coverImage
    if (coverImage) {
      const coverFormData = new FormData();
      coverFormData.append("name", eventName); // Assuming eventName is the "name"
      coverFormData.append("date", date); // Assuming eventName is the "name"

      coverFormData.append("coverImage", coverImage);
  
      // Print the keys and values of the formData
      for (let [key, value] of coverFormData.entries()) {
        console.log(`${key}: ${value}`);
      }
  
      try {
        const response = await fetch("https://www.meghnabordikar.com/upload/api/uploadcover", {
          method: "POST",
          body: coverFormData,
        });
  
        const data = await response.json();
        if (response.ok) {
          setMessage((prevMessage) =>
            prevMessage
              ? `${prevMessage}\nCover image uploaded successfully!`
              : `Cover image uploaded successfully!`
          );
          console.log("Cover image uploaded successfully");
        } else {
          allUploadedSuccessfully = false;
          message += `Failed to upload image ${file.name}.\n`;
          console.error(`File: ${file.name} - Error: ${data.error}`);
        }
      } catch (error) {
        allUploadedSuccessfully = false;
        message += `Error uploading image ${file.name}.\n`;
        console.error(`File: ${file.name} - Network error`);
      }
    }

    // Set the final message after all uploads
    if (allUploadedSuccessfully) {
      setMessage("All files uploaded successfully!");
      alert("All files uploaded successfully!");
    } else {
      setMessage(message);
    }
  };

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  
    return () => {
      document.body.style.overflow = "";
    };
  }, [isLoading]);

  return (
      <div className="relative">
          <AdminHeader />
          {/* D E S K T O P */}
          <div className="flex xxs:hidden tabPt:flex flex-col w-full pt-[7vw] bg-cbg2 font-poppins text-[1.05vw]" style={{ minHeight: `calc(100vh - 30vw)` }}>
            {isLoading && (
              <div className="absolute bottom-[0vw] left-1/2 -translate-x-1/2 z-[22] h-full w-full bg-white bg-opacity-50">
                <div className="flex flex-col justify-center items-center">
                  <motion.div
                    className="absolute top-[20vw] h-screen"
                    style={{
                      width: "5vw",
                      height: "5vw",
                      border: "10px solid rgba(232, 81, 18, 0.5)", // Outer ring
                      borderTop: "10px solid #E85112", // Highlighted moving part
                      borderRadius: "50%",
                      boxSizing: "border-box",
                    }}
                    animate={{
                      rotate: 360,
                    }}
                    transition={{
                      duration: 1, // Time for one full spin
                      repeat: Infinity, // Infinite loop
                      ease: "linear", // Smooth, consistent motion
                    }}
                  />
                </div>
              </div>
            )}
            <div className='bg-cbg2 px-[3vw]'>
              <div className='py-[2vw] font-poppinsSemiBold text-[1.2vw] text-start '>
                {t.gallery_upload_header}
              </div>
              <div className='w-full mb-[2vw] border-b-2 border-[#D0CCCB]'></div>
            </div>
            <form onSubmit={handleSubmit} className="flex flex-row bg-white">
              <div className="flex flex-col justify-start items-start w-[30vw] pl-[3vw] pr-[1vw] pt-[1vw] pb-[2vw] gap-[0.5vw]">
                <div className="w-full px-[1vw] text-[1.5vw] text-start">{t.uploadEvent}</div>
                <div className="flex-grow w-[24vw] max-h-fit flex flex-row justify-between items-center ml-[1vw] mr-[2vw] mb-[0.5vw] px-[0.5vw] py-[0.25vw] gap-[0.5vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2">  
                  <label htmlFor="date" className="mr-[1vw]">{t.selectDate}</label>
                  <input
                    className="flex-grow py-[0.25vw] flex flex-row justify-evenly items-center focus:outline-none"
                    type="date"
                    id="date"
                    value={date}
                    onChange={handleDateChange}
                    required
                    pattern="\{4}-\d{2}-\d{2}"
                  />
                </div>
                <div className="flex-grow w-[24vw] max-h-fit flex flex-row justify-between items-center ml-[1vw] mr-[2vw] mb-[0.5vw] px-[0.5vw] py-[0.25vw] gap-[0.5vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2">
                    <label className="w-[80%] text-start flex flex-col justify-center items-group-center items-start" htmlFor="eventName">{t.eventName}</label>
                    <input
                      className="w-full flex-grow py-[0.25vw] px-[0.5vw] flex flex-row justify-evenly items-center focus:outline-none"
                      type="text"
                      id="eventName"
                      value={eventName}
                      onChange={(e) => setEventName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="flex-grow w-[24vw] max-h-fit flex flex-row justify-between items-center ml-[1vw] mr-[2vw] mb-[0.5vw] px-[0.5vw] py-[0.25vw] gap-[0.5vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2">
                    <label className="w-[80%] text-start flex flex-col justify-center items-group-center items-start" htmlFor="location">{t.eventLoc}</label>
                    <input
                      className="w-full flex-grow py-[0.25vw] px-[0.5vw] flex flex-row justify-evenly items-center focus:outline-none"
                      type="text"
                      id="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      required
                    />
                  </div>
                  <div className="w-full flex flex-col justify-between items-start px-[1vw] gap-[0.5vw] mb-[0.5vw] ">
                    <label htmlFor="content">{t.eventCont}</label>
                    <textarea
                      className="w-full min-h-[7vw] resize-y flex-grow py-[0.5vw] px-[0.5vw] flex flex-col justify-evenly items-start border border-[#DED5D5] rounded-[0.2vw] shadow-cs2 focus:outline-none"
                      id="content"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      required
                    />
                  </div>
                  <div className="flex-grow w-[24vw] max-h-fit flex flex-row justify-between items-center ml-[1vw] mr-[2vw] mb-[0.5vw] px-[0.5vw] py-[0.25vw] gap-[0.5vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2">
                    <label className="w-[80%] text-start flex flex-col justify-center items-group-center items-start" htmlFor="coverImage">{t.covImg}</label>
                    <input
                      className="w-full flex-grow py-[0.25vw] px-[0.5vw] flex flex-row justify-evenly items-center focus:outline-none"
                      type="file"
                      id="coverImage"
                      onChange={handleCoverImageChange}
                      accept="image/*"
                      required
                    />
                    </div>
                <button className="w-[15vw] py-[0.75vw] mx-[1vw] rounded-[0.2vw] flex justify-center items-center bg-cor text-white" type="submit">{t.upload}</button>
              </div>
              <div className="flex flex-grow p-[1vw] select-none">
                <div className="grid grid-cols-3 gap-[1vw] w-full py-[2vw]">
                  {files.map((file, index) => (
                    <div key={index} className="relative">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`file-${index}`}
                        className="w-full h-[12.5vw] object-cover rounded-[1vw]"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(file)}
                        className="absolute top-[0.5vw] right-[0.5vw] flex justify-center items-center h-[3vw] w-[3vw] bg-black text-white rounded-full cursor-pointer"
                      >
                        ✕
                      </button>
                    </div>
                  ))}
                  {/* Custom Upload Button */}
                  
                  <label
                    title="Click here to upload pictures"
                    htmlFor="file"
                    className="h-[12.5vw] w-full flex justify-center items-center border-2 border-dashed border-cbg5 bg-cbg4 rounded-[1vw] cursor-pointer"
                  >
                    <span className="text-[9.5vw] h-[10vw] w-[10vw] text-white flex justify-center items-center bg-cbg5 rounded-full">+</span>
                  </label>
            
                  {/* Hidden File Input */}
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    multiple
                    accept="image/*"
                    style={{ display: "none" }} // Hides the native file input
                  />
                </div>
              </div>
            </form>
            {/* {message && <p>{message}</p>} */}
          </div>

          {/* M O B I L E */}
          <div className="flex tabPt:hidden flex-col w-full pt-[7vw] bg-cbg2 font-poppins text-[4vw]">
            {isLoading && (
              <div className="absolute bottom-[0vw] left-1/2 -translate-x-1/2 z-[22] h-full w-full bg-white bg-opacity-50">
                <div className="flex flex-col justify-center items-center">
                  <motion.div
                    className="absolute top-[50vh] h-screen"
                    style={{
                      width: "10vw",
                      height: "10vw",
                      border: "10px solid rgba(232, 81, 18, 0.5)", // Outer ring
                      borderTop: "10px solid #E85112", // Highlighted moving part
                      borderRadius: "50%",
                      boxSizing: "border-box",
                    }}
                    animate={{
                      rotate: 360,
                    }}
                    transition={{
                      duration: 1, // Time for one full spin
                      repeat: Infinity, // Infinite loop
                      ease: "linear", // Smooth, consistent motion
                    }}
                  />
                </div>
              </div>
            )}
            <div className='bg-cbg2 px-[5vw]'>
              <div className='pt-[7.5vw] font-poppinsSemiBold text-[3vw] text-start leading-[7.5vw]'>
                {t.gallery_upload_header}
              </div>
              <div className='w-full mb-[2vw] border-b-2 border-[#D0CCCB]'></div>
            </div>
            <form onSubmit={handleSubmit} className="flex flex-col bg-white px-[3vw]">
              <div className="flex flex-col justify-start items-start w-full pr-[1vw] pt-[1vw] pb-[2vw] gap-[3vw]">
                <div className="w-full px-[1vw] text-[4.5vw] text-start font-poppinsMedium">{t.uploadEvent}</div>
                  <div className="w-full flex flex-row justify-between items-center px-[2vw] gap-[3vw] relative border border-[#DED5D5] rounded-[0.2vw] shadow-cs2 ">  
                    <label htmlFor="date" className="mr-[1vw] w-[25vw] text-start">{t.selectDate}</label>
                    <input
                      className="flex py-[1.5vw] px-[2vw] min-w-[30vw] opacity-100 flex-row justify-evenly items-center bg-white focus:outline-none"
                      type="date"
                      id="datemob"
                      value={date}
                      onChange={handleDateChange}
                      required
                      pattern="\{4}-\d{2}-\d{2}"
                      placeholder="dd-mm-yyyy"
                    />
                  </div>
                <div className="w-full flex flex-col justify-between items-start px-[1vw] gap-[0.5vw]">
                  <label htmlFor="eventName">{t.eventName}</label>
                  <input
                    className="w-full py-[0.5vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2"
                    type="text"
                    id="eventName"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full flex flex-col justify-between items-start px-[1vw] gap-[0.5vw]">
                  <label htmlFor="location">{t.eventLoc}</label>
                  <input
                    className="w-full py-[0.5vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2"
                    type="text"
                    id="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full flex flex-col justify-between items-start px-[1vw] gap-[0.5vw]">
                  <label htmlFor="content">{t.eventCont}</label>
                  <textarea
                    className="w-full py-[3.25vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2"
                    id="content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full flex flex-col justify-between items-start px-[1vw] gap-[0.5vw]">
                  <label htmlFor="coverImage">{t.covImg}</label>
                  <input
                    className="w-full py-[0.5vw] border border-[#DED5D5] rounded-[0.2vw] shadow-cs2"
                    type="file"
                    id="coverImage"
                    onChange={handleCoverImageChange}
                    accept="image/*"
                    required
                  />
                </div>

                <button className="w-[40vw] py-[1vw] mx-[1vw] rounded-[1vw] flex justify-center items-center bg-cor text-white" type="submit">Upload</button>
              </div>
              <div className="flex flex-grow p-[1vw] select-none">
                <div className="grid grid-cols-2 gap-[3vw] w-full pt-[2vw] pb-[3vw]">
                  {files.map((file, index) => (
                    <div key={index} className="relative">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`file-${index}`}
                        className="w-full h-[30vw] object-cover rounded-[2vw]"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(file)}
                        className="absolute top-[2vw] right-[2vw] flex justify-center items-center h-[5vw] w-[5vw] bg-black text-[3vw] text-white rounded-full cursor-pointer"
                      >
                        ✕
                      </button>
                    </div>
                  ))}
                  {/* Custom Upload Button */}
                  <label
                    htmlFor="file"
                    className="h-[30vw] w-full flex justify-center items-center border-2 border-dashed border-cbg5 bg-cbg4 rounded-[2vw] cursor-pointer"
                  >
                    <span className="text-[15vw] h-[20vw] w-[20vw] text-white flex justify-center items-center bg-cbg5 rounded-full">+</span>
                  </label>
            
                  {/* Hidden File Input */}
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    multiple
                    accept="image/*"
                    style={{ display: "none" }} // Hides the native file input
                  />
                </div>
              </div>
            </form>
            {/* {message && <p>{message}</p>} */}
          </div>
          <Footer />
      </div>
  )
}
export default UploadEvent;
