import React, { useRef, useEffect, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../context/LanguageContext';
import { useLocation } from "react-router-dom";
import logo from "../../images/header_logo.png";
import translations from '../../components/translation.js';

const AdminHeader = () => {
  const navigate = useNavigate();
  const { language, changeLanguage } = useLanguage();
  const t = translations[language];
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const absoluteDivRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState("uploadpic");

  const location = useLocation();
  const curr = location.pathname.split("/").filter(Boolean).pop();

  const handleLanguageChange = (e) => {
    changeLanguage(e.target.value);
  };

  const toggleMenu = () => {
    setIsMenuVisible((prev) => !prev);
  };

//   const scrollToSection = (section) => {
//     setActiveMenu("social");
//     localStorage.setItem("activeMenu", "social");
//     // handleSvgClick();
//     window.location.href = `/social#${section}`;
//   };

  
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    localStorage.setItem("activeMenu", menu);
    navigate(menu === "home" ? "/" : `/${menu}`);
  };

  // M O B I L E - M E N U
  const handleSvgClick = () => {
    setIsVisible((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      absoluteDivRef.current &&
      !absoluteDivRef.current.contains(event.target)
    ) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const savedMenu = localStorage.getItem("activeMenu");
    if (savedMenu) {
      setActiveMenu(savedMenu);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  
  useEffect(() => {
    console.log("current path: ", curr)
    setActiveMenu(curr || "uploadpic");
  }, [location.pathname]);

  return (
    <div className="w-full h-fit bg-transparent fixed top-0 z-[1] select-none font-poppins">
      {/* D E S K T O P */}
      <div className="w-full px-[2vw] py-[1vw] bg-cor flex xxs:hidden tabPt:flex flex-row justify-between items-center text-white">
        <div className="flex flex-row items-center justify-between gap-[1vw]">
          <img src={logo} className="h-[4.5vw]" />
          <div className="text-[1.5vw] font-semibold">
            {t.headerName}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center gap-[2vw]">
          {/* <div className={`cursor-pointer text-[0.9vw] ${activeMenu === 'home' ? "border-b-2 border-white" : ""}`} onClick={() => {handleMenuClick('home')}}>{t.homeUpload}</div>
          <div className={`cursor-pointer text-[0.9vw] ${activeMenu === 'about' ? "border-b-2 border-white" : ""}`} onClick={() => {handleMenuClick("about")}}>{t.aboutUpload}</div>
          <div
            className={`cursor-pointer text-[0.9vw] relative flex flex-row items-center justify-center gap-[0.5vw] transition-all duration-200 ease-in-out group
                ${activeMenu === 'social' ? "border-b-2 border-white" : ""}
              `}
            // onClick={() => { navigate("/social") }}
            // onMouseEnter={handleMouseEnter}
          >
            <div className="" onClick={() => {handleMenuClick("social") }}>{t.socialUpload}</div>
            <svg className="p-[0.2vw] h-[1vw] w-[1vw] z-[20] bg-white rounded-[0.1vw]" onClick={(e) => { e.stopPropagation(); toggleMenu();}} xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9" fill="none">
              <path fill="#E85112" fillRule="evenodd" clipRule="evenodd" d="M7.20232 8.19458C7.01922 8.39014 6.77091 8.5 6.512 8.5C6.25309 8.5 6.00478 8.39014 5.82167 8.19458L0.298088 2.29348C0.204831 2.19725 0.130445 2.08214 0.0792721 1.95487C0.0280992 1.82761 0.00116347 1.69072 3.68659e-05 1.55222C-0.00108974 1.41371 0.0236155 1.27635 0.0727106 1.14815C0.121806 1.01995 0.194308 0.903476 0.285986 0.805532C0.377664 0.707587 0.486683 0.63013 0.60668 0.57768C0.726678 0.525229 0.855253 0.498836 0.9849 0.500039C1.11455 0.501243 1.24267 0.53002 1.3618 0.58469C1.48093 0.639361 1.58867 0.71883 1.67874 0.818461L6.512 5.98206L11.3453 0.818461C11.5294 0.628443 11.7761 0.523299 12.0321 0.525676C12.2881 0.528053 12.533 0.63776 12.714 0.831168C12.8951 1.02458 12.9977 1.28621 13 1.55972C13.0022 1.83323 12.9038 2.09674 12.7259 2.29348L7.20232 8.19458Z"/>
            </svg>
            <div
              className={`absolute top-[2vw] left-0 w-[13vw] rounded-[0.2vw] p-[0.5vw] shadow-cs4 text-start bg-white text-black z-[0] transition-all duration-200 ease-in-out ${isMenuVisible ? "block" : "hidden"}`}
              onMouseLeave={handleMouseLeave}
            >
              <div className="p-[0.5vw] hover:text-white hover:bg-cor rounded-[0.25vw] transition-all duration-200 ease-in-out" onClick={() => {scrollToSection("edSec")}}>{t.education}</div>
              <div className="p-[0.5vw] hover:text-white hover:bg-cor rounded-[0.25vw] transition-all duration-200 ease-in-out" onClick={() => {scrollToSection("rdSec")}}>{t.ruralDev}</div>
              <div className="p-[0.5vw] hover:text-white hover:bg-cor rounded-[0.25vw] transition-all duration-200 ease-in-out" onClick={() => {scrollToSection("envSec")}}>{t.env}</div>
              <div className="p-[0.5vw] hover:text-white hover:bg-cor rounded-[0.25vw] transition-all duration-200 ease-in-out" onClick={() => {scrollToSection("ythSec")}}>{t.youthEmp}</div>
              <div className="p-[0.5vw] hover:text-white hover:bg-cor rounded-[0.25vw] transition-all duration-200 ease-in-out" onClick={() => {scrollToSection("fwSec")}}>{t.farmersWel}</div>
              <div className="p-[0.5vw] hover:text-white hover:bg-cor rounded-[0.25vw] transition-all duration-200 ease-in-out" onClick={() => {scrollToSection("wSec")}}>{t.womenEmp}</div>
            </div>
          </div> */}
          <div className={`cursor-pointer uppercase text-[0.9vw] ${activeMenu === 'uploadpic' ? "border-b-2 border-white" : ""}`} onClick={() => handleMenuClick("uploadpic")}>{t.galleryUpload}</div>
          <div className={`cursor-pointer uppercase text-[0.9vw] ${activeMenu === 'uploadpress' ? "border-b-2 border-white" : ""}`} onClick={() => handleMenuClick("uploadpress")}>{t.pressUpload}</div>
          <div className={`cursor-pointer uppercase text-[0.9vw] ${activeMenu === 'uploadevent' ? "border-b-2 border-white" : ""}`} onClick={() => handleMenuClick("uploadevent")}>{t.eventUpload}</div>
        </div>
        <div className="h-[2.75vw] flex flex-row items-center justify-between gap-[2vw]">
          <div className="h-full bg-white flex flex-row items-center justify-between px-[1vw] py-[0.2vw] rounded-full opacity-0 pointer-events-none">
            <input className="text-black text-[0.9vw] w-[4vw] placeholder-black" placeholder={`${t.search}`}/>
            <svg className="h-[1vw] w-[1vw]" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M15.1441 15.2725L9.94642 10.1563C9.44716 10.5531 8.87302 10.8602 8.22399 11.0776C7.57495 11.2949 6.92259 11.4036 6.2669 11.4036C4.66794 11.4036 3.31462 10.8872 2.20694 9.85454C1.09925 8.82123 0.54541 7.55908 0.54541 6.06811C0.54541 4.57713 1.09859 3.31437 2.20494 2.27981C3.31196 1.24464 4.66462 0.727051 6.2629 0.727051C7.86186 0.727051 9.21584 1.24371 10.3249 2.27702C11.4339 3.31033 11.9884 4.5731 11.9884 6.06531C11.9884 6.71237 11.8656 7.33863 11.6199 7.94409C11.3736 8.54955 11.0508 9.06744 10.6514 9.49778L16 14.4127L15.1441 15.2725ZM6.2669 10.4712C7.59159 10.4712 8.71026 10.0458 9.6229 9.19506C10.5349 8.34431 10.9909 7.30075 10.9909 6.06438C10.9909 4.82863 10.5349 3.78538 9.6229 2.93464C8.71093 2.08389 7.59259 1.65852 6.2679 1.65852C4.9432 1.65852 3.82453 2.08389 2.91189 2.93464C1.99991 3.78538 1.54392 4.82863 1.54392 6.06438C1.54392 7.30013 1.99991 8.34338 2.91189 9.19412C3.82387 10.0449 4.9422 10.4712 6.2669 10.4712Z" fill="#E85112"/>
            </svg>
          </div>
          <div className="flex flex-col items-center relative bg-white rounded-[0.25vw]">
            <select
              className="bg-transparent text-cor h-[2.25vw] w-[6vw] pl-[0.5vw] pr-[1vw] flex flex-row justify-center items-center text-[0.9vw] font-semibold shadow-cs3 rounded-sm focus:outline-none z-[5] cursor-pointer"
              style={{ WebkitAppearance: "none" }}
              defaultValue="en" value={language} onChange={handleLanguageChange}
            >
              <option className="font-poppinsSemiBold" value="en">{t.en}</option>
              <option className="font-poppinsSemiBold" value="mr">{t.mr}</option>
              <option className="font-poppinsSemiBold" value="hi">{t.hi}</option>
            </select>
            <svg className="h-[1.85vw] w-[1.85vw] absolute top-1/2 -translate-y-1/2 right-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15 15" fill="none">
              <path d="M7.51666 9.21084L4.46222 6.21539L4.79107 5.88006L7.51018 8.54666L10.1768 5.82756L10.5121 6.15641L7.51666 9.21084Z" fill="#E85112"/>
            </svg>
          </div>
          <div 
            className="bg-white text-cor text-center h-[2.25vw] w-[7vw] flex flex-row justify-center items-center text-[0.9vw] font-semibold shadow-cs3 rounded-[0.25vw] focus:outline-none cursor-pointer"
            onClick={() => {navigate("/"); localStorage.removeItem("token");}}
          >
            {/* {t.contactUpload} */}
            LOGOUT
          </div>
        </div>
      </div>

      {/* M O B I L E */}
      <div className="w-full px-[2vw] py-[2vw] bg-cor flex tabPt:hidden flex-row justify-start items-center text-white relative">
        {isVisible && (
          <div className="absolute top-0 left-0 h-screen w-full bg-black z-[20] bg-opacity-50"></div>
        )}
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    initial={{ opacity: 0, scale: 1, x: -200 }}
                    animate={{ opacity: 1, scale: 1, x: 0 }}
                    exit={{ opacity: 0, scale: 1, x: -200 }}
                    transition={{
                        type: 'tween',
                        duration: 0.2,
                        ease: 'easeInOut'
                    }}
                    ref={absoluteDivRef}
                    className={`absolute left-0 top-0 h-screen w-fit min-w-[70vw] px-[3.5vw] pt-[2vw] pb-[7.5vw] bg-white text-black flex-col justify-start items-start gap-[2vw] flex z-[20]`}
                >
                  <div className="w-full flex flex-row justify-between items-center gap-[3vw]">
                      <img src={logo} className="h-[10vw]" />
                      <div className="text-[3.5vw] font-semibold text-cor font-poppinsMedium">
                        {t.headerName}
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25.5" viewBox="0 0 20 17" fill="none" onClick={handleSvgClick}>
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.2402 3.97597C15.2402 3.7131 15.0271 3.5 14.7643 3.5L5.72109 3.5C5.45822 3.5 5.24513 3.7131 5.24513 3.97597C5.24513 4.23884 5.45822 4.45194 5.72109 4.45194L14.7643 4.45194C15.0271 4.45194 15.2402 4.23884 15.2402 3.97597Z" fill="black" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.17005 5.37867L0.141072 8.40771C-0.0448024 8.59359 -0.0448023 8.89496 0.141072 9.08083L3.17005 12.1099C3.35592 12.2958 3.65728 12.2958 3.84315 12.1099C4.02902 11.924 4.02902 11.6226 3.84315 11.4368L1.62669 9.22024L14.7643 9.22024C15.0271 9.22024 15.2402 9.00714 15.2402 8.74427C15.2402 8.4814 15.0271 8.2683 14.7643 8.2683L1.62669 8.2683L3.84315 6.05179C4.02902 5.86591 4.02902 5.56454 3.84315 5.37867C3.65728 5.19279 3.35592 5.19279 3.17005 5.37867Z" fill="black" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.2402 13.5121C15.2402 13.2492 15.0271 13.0361 14.7643 13.0361L5.72109 13.0361C5.45822 13.0361 5.24513 13.2492 5.24513 13.5121C5.24513 13.775 5.45822 13.9881 5.72109 13.9881L14.7643 13.9881C15.0271 13.9881 15.2402 13.775 15.2402 13.5121Z" fill="black" />
                      </svg>
                  </div>
                  <div className="flex flex-col justify-between items-start w-full gap-[5vw] pt-[5vw] pl-[4vw] text-start">
                      <div className={`cursor-pointer font-poppinsMedium text-[3.5vw] rounded-[1vw] w-full p-[1vw] pl-[2vw] ${activeMenu === 'uploadpic' ? 'bg-cor text-white' : ''}`} onClick={() => handleMenuClick("uploadpic")}>{t.galleryUpload}</div>
                      <div className={`cursor-pointer font-poppinsMedium text-[3.5vw] rounded-[1vw] w-full p-[1vw] pl-[2vw] ${activeMenu === 'uploadpress' ? 'bg-cor text-white' : ''}`} onClick={() => handleMenuClick("uploadpress")}>{t.pressUpload}</div>
                      <div className={`cursor-pointer font-poppinsMedium text-[3.5vw] rounded-[1vw] w-full p-[1vw] pl-[2vw] ${activeMenu === 'uploadevent' ? 'bg-cor text-white' : ''}`} onClick={() => handleMenuClick("uploadevent")}>{t.eventUpload}</div>
                      <div className="w-full mt-[2vw] border-b-2 border-gray-300"></div>
                      <div className={`cursor-pointer font-poppinsMedium text-[3.5vw] rounded-[1vw] w-full p-[1vw] pl-[2vw] ${activeMenu === 'home' ? 'bg-cor text-white' : ''}`} onClick={() => {  localStorage.removeItem("token"); handleMenuClick("home"); }}>LOGOUT</div>
                  </div>
                </motion.div>
            )}
        </AnimatePresence>
        <div className="w-[8.5vw]">
          <svg className="" xmlns="http://www.w3.org/2000/svg" width="30" height="25.5" viewBox="0 0 20 17" fill="none" onClick={handleSvgClick}>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.2402 3.97597C15.2402 3.7131 15.0271 3.5 14.7643 3.5L5.72109 3.5C5.45822 3.5 5.24513 3.7131 5.24513 3.97597C5.24513 4.23884 5.45822 4.45194 5.72109 4.45194L14.7643 4.45194C15.0271 4.45194 15.2402 4.23884 15.2402 3.97597Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.83 5.37867L19.859 8.40771C20.0448 8.59359 20.0448 8.89496 19.859 9.08083L16.83 12.1099C16.6441 12.2958 16.3427 12.2958 16.1569 12.1099C15.971 11.924 15.971 11.6226 16.1569 11.4368L18.3733 9.22024L5.2357 9.22024C4.97283 9.22024 4.75974 9.00714 4.75974 8.74427C4.75974 8.4814 4.97283 8.2683 5.2357 8.2683L18.3733 8.2683L16.1569 6.05179C15.971 5.86591 15.971 5.56454 16.1569 5.37867C16.3427 5.19279 16.6441 5.19279 16.83 5.37867Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.2402 13.5121C15.2402 13.2492 15.0271 13.0361 14.7643 13.0361L5.72109 13.0361C5.45822 13.0361 5.24513 13.2492 5.24513 13.5121C5.24513 13.775 5.45822 13.9881 5.72109 13.9881L14.7643 13.9881C15.0271 13.9881 15.2402 13.775 15.2402 13.5121Z" fill="white" />
          </svg>
        </div>
        <div className="flex flex-row items-center justify-between gap-[1vw]">
          <img src={logo} className="h-[8vw]" />
          <div className="text-[4vw] font-semibold">
            {t.headerName}
          </div>
        </div>
        <div className="w-fit ml-auto flex flex-row items-center justify-between gap-[2vw]">
          <img className="h-[5vw]" src="https://img.icons8.com/?size=100&id=7695&format=png&color=FFFFFF" />
          <div className="flex flex-col items-center relative bg-white rounded-sm">
            <select 
              className="bg-transparent text-cor h-[5vw] w-fit pl-[1vw] pr-[4vw] flex flex-row justify-center items-center text-[3vw] font-semibold shadow-cs3 rounded-sm focus:outline-none z-[5] cursor-pointer"
              style={{ WebkitAppearance: "none" }} 
              defaultValue="en" value={language} onChange={handleLanguageChange}
            >
              <option className="font-poppinsSemiBold" value="en">{t.en}</option>
              <option className="font-poppinsSemiBold" value="mr">{t.mr}</option>
              <option className="font-poppinsSemiBold" value="hi">{t.hi}</option>
            </select>
            <svg className="h-[2.5vw] w-[2.5vw] absolute top-1/2 -translate-y-1/2 right-[1vw]" xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13 9" fill="none">
              <path fill="#E85112" fillRule="evenodd" clipRule="evenodd" d="M7.20232 8.19458C7.01922 8.39014 6.77091 8.5 6.512 8.5C6.25309 8.5 6.00478 8.39014 5.82167 8.19458L0.298088 2.29348C0.204831 2.19725 0.130445 2.08214 0.0792721 1.95487C0.0280992 1.82761 0.00116347 1.69072 3.68659e-05 1.55222C-0.00108974 1.41371 0.0236155 1.27635 0.0727106 1.14815C0.121806 1.01995 0.194308 0.903476 0.285986 0.805532C0.377664 0.707587 0.486683 0.63013 0.60668 0.57768C0.726678 0.525229 0.855253 0.498836 0.9849 0.500039C1.11455 0.501243 1.24267 0.53002 1.3618 0.58469C1.48093 0.639361 1.58867 0.71883 1.67874 0.818461L6.512 5.98206L11.3453 0.818461C11.5294 0.628443 11.7761 0.523299 12.0321 0.525676C12.2881 0.528053 12.533 0.63776 12.714 0.831168C12.8951 1.02458 12.9977 1.28621 13 1.55972C13.0022 1.83323 12.9038 2.09674 12.7259 2.29348L7.20232 8.19458Z"/>
            </svg>
          </div>
          {/* <div 
            className="bg-white text-cor text-center h-[5vw] w-fit px-[1vw] flex flex-row justify-center items-center text-[3vw] font-semibold shadow-cs3 rounded-sm focus:outline-none cursor-pointer" 
            onClick={() => {navigate("/"); localStorage.removeItem("token");}}
          >
            LOGOUT
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;