import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for DatePicker
import styles from "./press.module.css"; // Import CSS module
import share from "../images/share.png";
import facebook from "../images/Facebook.png";
import instagram from "../images/Instagram.png";

import twiter from "../images/Twitter.png";
import whatsapp from "../images/whatsapp.jpg";
import gmail from "../images/gmail.png";
import message from "../images/message.jpg";
import Header from '../components/Header/Header.js';
import Footer from '../components/Footer/Footer.js';
import { useLanguage } from '../context/LanguageContext';
import translations from '../components/translation.js';
const Press = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const [images, setImages] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedDate, setSelectedDate] = useState(null); // State for selected date
  const [visiblePopupIndex, setVisiblePopupIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  useEffect(() => {
    fetch("https://www.meghnabordikar.com/pressupload/images")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.images)) {
          setImages(data.images);
          setFilteredImages(data.images);
          console.log(data); // Debugging the fetched data
        } else {
          setImages([]);
          setFilteredImages([]);
        }
      })
      .catch(() => {
        setImages([]);
        setFilteredImages([]);
      });
  }, []);

  const filterImages = (category) => {
    setSelectedCategory(category);
    setSelectedDate(null); // Reset date filter when changing categories
    if (category === "All") {
      setFilteredImages(images);
    } else {
      setFilteredImages(
        images.filter((image) =>
          image.categories?.includes(category.toLowerCase())
        )
      );
    }
  };
  const toggleSharePopup = (index) => {
    setVisiblePopupIndex(visiblePopupIndex === index ? null : index);
  };
  const resetHeight = () => {
    setVisiblePopupIndex(null);
  };

  const filterByDate = (date) => {
    setSelectedCategory("All"); // Reset category filter when changing dates
    setSelectedDate(date);
    if (!date) {
      setFilteredImages(images);
    } else {
      const formattedDate = date.toLocaleDateString("en-CA"); // Format to YYYY-MM-DD in the local timezone
      console.log(formattedDate);

      setFilteredImages(images.filter((image) => image.date === formattedDate));
    }
  };

  return (
    <div className={styles.galleryContainer}>
      <Header/>
      <div className={styles.headcon}>
        <h2 className={styles.header}>
        {t.press_header}
        </h2>

        {/* Filters */}
        <div className={styles.filters}>
          <button
            onClick={() => filterImages("All")}
            className={selectedCategory === "All" ? styles.active : ""}
          >
            {t.All}
          </button>
          <button
            onClick={() => filterImages("News")}
            className={selectedCategory === "News" ? styles.active : ""}
          >
            {t.News}
          </button>
          <button
            onClick={() => filterImages("Interviews")}
            className={selectedCategory === "Interviews" ? styles.active : ""}
          >
            {t.Interviews}
          </button>
          <button
            onClick={() => filterImages("Press Release")}
            className={
              selectedCategory === "Press Release" ? styles.active : ""
            }
          >
            {t.PressRelease}
          </button>
          <button
            onClick={() => filterImages("Blogs")}
            className={selectedCategory === "Blogs" ? styles.active : ""}
          >
            {t.Blogs}
          </button>
        </div>

        {/* Date Picker */}
        <div className={styles.datePickerContainer}>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => filterByDate(date)}
            placeholderText={t.press_select_date}
            className={styles.datePicker}
          />
        </div>
      </div>

      {/* Image Grid */}
      <div className={styles.imageGrid}>
        {filteredImages.length > 0 ? (
          filteredImages.map((image) => (
            <div className={styles.imageCard} key={image._id}>
              <img
                src={`https://www.meghnabordikar.com/${image.path}`}
                alt={image.title}
                className={styles.image}
              />
              <div className={styles.imageInfo}>
                <h3 className={styles.imageTitle}>
                  <a href={image.url} target="_blank" rel="noopener noreferrer">
                    {image.title}
                  </a>
                </h3>{" "}
              <div className={styles.sharesection}>
                <p className={styles.imageDate}>{image.date}</p>{" "}
                <img
                  src={share}
                  alt="share"
                  className={styles.share}
                  onClick={() => toggleSharePopup(image._id)}
                ></img>
              </div>{" "}
              {visiblePopupIndex === image._id && (
                <div className={styles.vanish}>
                  <div className={styles.sharePopup}>
                    <ul>
                      <li>
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(image.url);
                            alert("Link copied to clipboard!");
                          }}
                          className={styles.copylink}
                        >
                          Copy Link
                        </button>
                      </li>
                      <li>
                        <a
                          href={`https://wa.me/?text=${encodeURIComponent(
                            image.url
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={whatsapp}
                            alt="WhatsApp"
                            className={styles.sicon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                            image.url
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={facebook}
                            alt="Facebook"
                            className={styles.sicon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                            image.description
                          )}&url=${encodeURIComponent(image.url)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={twiter}
                            alt="Twitter"
                            className={styles.sicon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.instagram.com/share?url=${encodeURIComponent(
                            image.url
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={instagram}
                            alt="Instagram"
                            className={styles.sicon}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`mailto:?subject=${encodeURIComponent(
                            image.title
                          )}&body=${encodeURIComponent(image.url)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={gmail}
                            alt="Gmail"
                            className={styles.sgicon}
                          />
                        </a>
                      </li>
                      {/* <li>
                                            <a
                                              href={`sms:?body=${encodeURIComponent(item.link)}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <img src={message} alt="Message" className={styles.sicon} />
                                            </a>
                                          </li> */}
                      <li>
                        <button
                          className={styles.closelink}
                          onClick={resetHeight} // Replace this with the actual state updater
                        >
                          Close All ✖
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            </div>

          ))
        ) : (
          <p className={styles.noData}>
            No images available for the selected filters.
          </p>
        )}
      </div>
    <Footer/>
    </div>
  );
};

export default Press;
