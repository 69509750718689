import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import classes from '../Social/Social.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { useLanguage } from '../../context/LanguageContext';
import translations from '../../components/translation.js';

const Gallery = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const [images, setImages] = useState([]);
  const [filter, setFilter] = useState('all');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch("https://www.meghnabordikar.com/api/images");
        const data = await response.json();
        if (response.ok) {
          // console.log(data)
          setImages(data.images);
        } else {
          console.error("Failed to fetch images:", data.error);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const hadnleView = (e) => {
    e.stopPropagation();
    fetch(selectedImage)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Meghna Bordikar Gallery.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Download failed', error));
  }

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    document.getElementById(newFilter).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  };

  const filteredImages = filter === 'all'
    ? images
    : filter === 'Timeline'
    ? [...images].sort((a, b) => new Date(b.date) - new Date(a.date))
    : filter === 'date'
    ? images.filter(image => image.date === selectedDate)
    : images.filter(image => image.categories.includes(filter));

  const buttonClasses = (buttonFilter) => {
    const baseClasses = 'w-fit px-[1vw] py-[0.2vw] text-[0.95vw] font-poppinsMedium flex flex-row justify-start items-center flex-shrink-0 rounded-full border-2 border-cor transition-all duration-200 ease-in-out hover:bg-cor hover:text-white cursor-pointer';
    const activeClasses = filter === buttonFilter ? 'bg-cor text-white' : 'text-cor bg-white';
    return `${baseClasses} ${activeClasses}`;
  };

  const buttonClassesMob = (buttonFilter) => {
    const baseClasses = 'min-w-fit px-[3.5vw] py-[1vw] text-[2.75vw] font-poppinsMedium box-border flex flex-row justify-start items-center flex-shrink-0 rounded-full border-2 border-cor transition-all duration-200 ease-in-out hover:bg-cor hover:text-white';
    const activeClasses = filter === buttonFilter ? 'bg-cor text-white' : 'text-cor bg-white';
    return `${baseClasses} ${activeClasses}`;
  };

  return (
    <div>
      <Header />
      {/* D E S K T O P */}
      <div className='h-fit pt-[5vw] pb-[6.5vw] font-poppins flex xxs:hidden tabPt:flex flex-col justify-start items-center' style={{ minHeight: `calc(100vh - 30vw)` }}>
        <div className='bg-cbg2 pb-[2.5vw]'>
          <div className='pt-[4vw] px-[3vw] font-poppinsSemiBold text-[1.2vw] text-start'>
            {t.gallery_header}
          </div>
          {/* Filter Buttons */}
          <div className='hidden h-fit w-full mt-[2.5vw] px-[3vw]'>
            <div className='h-fit w-full flex flex-row justify-start gap-[2vw]'>
              <button className='w-[9.75vw] px-[1vw] py-[0.5vw] text-[0.8vw] font-poppinsMedium flex justify-start text-cor rounded-full border-2 border-cor'>{t.content_type}</button>
              <button className='w-[9.75vw] px-[1vw] py-[0.5vw] text-[0.8vw] font-poppinsMedium flex justify-start text-cor rounded-full border-2 border-cor'>{t.state}</button>
              <button className='w-[9.75vw] px-[1vw] py-[0.5vw] text-[0.8vw] font-poppinsMedium flex justify-start text-cor rounded-full border-2 border-cor'>{t.selectDate}</button>
            </div>
          </div>
          <div className='h-fit w-full mt-[2vw] px-[3vw]'>
            <div className='w-full mb-[1vw] border-b-2 border-[#D0CCCB]'></div>
            <div className='h-fit w-full flex flex-row justify-start gap-[1vw]'>
              <div className={`${buttonClasses('date')} group`}>
                {t.selectDate}
                <input
                  className={`${buttonClasses('date')} border-none group-hover:bg-cor group-hover:text-white pr-0 pl-[0.5vw] focus:outline-none ring-0`}
                  type="date"
                  value={selectedDate}
                  onChange={(e) => { handleFilterChange('date'); setSelectedDate(e.target.value); }}
                />
              </div>
              <button className={buttonClasses('all')} onClick={() => handleFilterChange('all')}>{t.All}</button>
              <button className={buttonClasses('Timeline')} onClick={() => handleFilterChange('Timeline')}>{t.timeline}</button>
              <button className={buttonClasses('sw')} onClick={() => handleFilterChange('sw')}>{t.socialWelfare}</button>
              <button className={buttonClasses('ed')} onClick={() => handleFilterChange('ed')}>{t.education}</button>
              <button className={buttonClasses('rd')} onClick={() => handleFilterChange('rd')}>{t.ruralDev}</button>
              <button className={buttonClasses('yd')} onClick={() => handleFilterChange('yd')}>{t.youthEmp}</button>
              <button className={buttonClasses('we')} onClick={() => handleFilterChange('we')}>{t.womenEmp}</button>
            </div>
          </div>
        </div>

        {/* Display Images */}
        <div className='h-fit w-full mt-[2.5vw] px-[3vw] min-h-[20vw]'>
          <div className='grid grid-cols-3 gap-[2vw]'>
            {filteredImages.map(image => (
              <img
                key={image.id}
                src={`https://www.meghnabordikar.com${image.path}`}
                alt={image.date}
                className="h-[22vw] w-full transition-transform duration-300 ease-in-out hover:scale-110 cursor-pointer rounded-[1vw]"
                onClick={() => setSelectedImage(`https://www.meghnabordikar.com${image.path}`)}
              />
            ))}

            {selectedImage && (
              <AnimatePresence>
                <div
                  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
                  onClick={() => setSelectedImage(null)}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8, y: 0 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    exit={{ opacity: 0, scale: 0.8, y: 0 }}
                    transition={{
                      type: 'tween',
                      duration: 0.2,
                      ease: 'easeIn',
                    }}
                    className="relative"
                  >
                    <img
                      src={selectedImage}
                      alt="Selected"
                      className="h-[33vw] rounded-md"
                      onClick={(e) => {e.stopPropagation()}}
                    />
                    <button
                      className="absolute top-[-4vw] right-0 text-white bg-black border-2 border-white h-[3vw] w-[3vw] text-[1.25vw] font-semibold flex justify-center items-center rounded-full"
                      title="Click to close"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedImage(null);
                      }}
                    >
                      ✕
                    </button>
                    <button
                      className="absolute top-[-4vw] right-[4vw] text-white bg-black border-2 border-white h-[3vw] w-fit px-[1vw] text-[1.25vw] flex flex-row justify-center items-center rounded-full"
                      title="Click to download"
                      onClick={(e) => {hadnleView(e)}}
                    >
                      Download ⬇
                    </button>
                  </motion.div>
                </div>
              </AnimatePresence>
            )}
          </div>
        </div>
      </div>

      {/* M O B I L E */}
      <div className='h-fit min-h-[7git0vh] pt-[10vw] pb-[6.5vw] font-poppins flex tabPt:hidden flex-col justify-start items-center flex-grow' style={{ minHeight: `calc(100vh - 80vw)` }}>
        <div className='bg-cbg2 pb-[2.5vw]'>
          <div className='pt-[7.5vw] px-[3vw] font-poppinsSemiBold text-[3vw] text-start leading-[7.5vw]'>
            {t.gallery_header}
          </div>
          {/* Filter Buttons */}
          <div className='hidden h-fit w-full mt-[2.5vw] px-[3vw]'>
            <div className='h-fit w-full flex flex-row justify-start gap-[2vw]'>
              <button className='w-[9.75vw] px-[1vw] py-[0.5vw] text-[0.8vw] font-poppinsMedium flex justify-start text-cor rounded-full border-2 border-cor'>{t.content_type}</button>
              <button className='w-[9.75vw] px-[1vw] py-[0.5vw] text-[0.8vw] font-poppinsMedium flex justify-start text-cor rounded-full border-2 border-cor'>{t.state}</button>
              <button className='w-[9.75vw] px-[1vw] py-[0.5vw] text-[0.8vw] font-poppinsMedium flex justify-start text-cor rounded-full border-2 border-cor'>{t.selectDate}</button>
            </div>
          </div>
          <div className='h-fit w-full mt-[2.5vw] px-[3vw]'>
            <div className='w-full mb-[1vw] border-b-2 border-[#D0CCCB]'></div>
            <div className={`h-fit w-[94vw] py-[2vw] flex flex-row justify-start gap-[2vw] overflow-x-auto scroll-smooth ${classes.noScroll}`}>
              <div id="date" className={`${buttonClassesMob('date')} group`}>
                {t.selectDate}
                <input
                  className={`${buttonClassesMob('date')} border-none group-hover:bg-cor group-hover:text-white pr-0 pl-[0.5vw] focus:outline-none ring-0`}
                  type="date"
                  value={selectedDate}
                  onChange={(e) => { handleFilterChange('date'); setSelectedDate(e.target.value); }}
                />
              </div>
              <button id="all" className={buttonClassesMob('all')} onClick={() => handleFilterChange('all')}>{t.All}</button>
              <button id="Timeline" className={buttonClassesMob('Timeline')} onClick={() => handleFilterChange('Timeline')}>{t.timeline}</button>
              <button id="sw" className={buttonClassesMob('sw')} onClick={() => handleFilterChange('sw')}>{t.socialWelfare}</button>
              <button id="ed" className={buttonClassesMob('ed')} onClick={() => handleFilterChange('ed')}>{t.education}</button>
              <button id="rd" className={buttonClassesMob('rd')} onClick={() => handleFilterChange('rd')}>{t.ruralDev}</button>
              <button id="yd" className={buttonClassesMob('yd')} onClick={() => handleFilterChange('yd')}>{t.youthEmp}</button>
              <button id="we" className={buttonClassesMob('we')} onClick={() => handleFilterChange('we')}>{t.womenEmp}</button>
            </div>
          </div>
        </div>

        {/* Display Images */}
        <div className='h-fit w-full mt-[2.5vw] px-[3vw]'>
          <div className='grid grid-cols-2 gap-[2vw]'>
            {filteredImages.map(image => (
              <img
                src={`https://www.meghnabordikar.com${image.path}`}
                alt={image.date}
                className="h-[30vw] w-full transition-transform duration-300 ease-in-out rounded-[1vw]"
                onClick={() => setSelectedImage(`https://www.meghnabordikar.com${image.path}`)}
              />
            ))}

            {selectedImage && (
              <AnimatePresence>
                <div
                  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
                  onClick={() => setSelectedImage(null)}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8, y: 0 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    exit={{ opacity: 0, scale: 0.8, y: 0 }}
                    transition={{
                      type: 'tween',
                      duration: 0.2,
                      ease: 'easeIn',
                    }}
                    className="relative"
                  >
                    <img
                      src={selectedImage}
                      alt="Selected"
                      className="max-w-[80vw] rounded-md"
                      onClick={(e) => {e.stopPropagation()}}
                    />
                    <button
                      className="absolute top-[-10vw] right-0 text-white bg-black border-2 border-white h-[7.5vw] w-[7.5vw] text-[3vw] font-semibold flex justify-center items-center rounded-full"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedImage(null);
                      }}
                    >
                      ✕
                    </button>
                    <button
                      className="absolute top-[-10vw] right-[10vw] text-white bg-black border-2 border-white h-[7.5vw] w-fit px-[3vw] text-[4vw] flex flex-row justify-center items-center rounded-full"
                      title="Click to download"
                      onClick={(e) => {hadnleView(e)}}
                    >
                      Download ⬇
                    </button>
                  </motion.div>
                </div>
              </AnimatePresence>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;