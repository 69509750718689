import React from "react";
import { useState, useEffect, useNavigate } from "react";

import styles from "./home.module.css"; // CSS Module
import cover from "../images/cover.png";
import cover2 from "../images/cover2.png";
import cover3 from "../images/cover3.png";
import cover4 from "../images/cover4.png";
import cover5 from "../images/cover5.png";
import cover1 from "../images/cover1.png";


import facebook from "../images/Facebook.png";
import instagram from "../images/Instagram.png";
import twitter from "../images/Twitter.png";
import youtube from "../images/YouTube.png";
import client from "../images/circle.png";
import meghna2 from "../images/home_img.png";
import i_2000 from "../images/2000.png";
import i_2001 from "../images/2001.png";
import i_3 from "../images/i_3.png";
import i_4 from "../images/i_4.png";
import i_5 from "../images/i_5.png";
import i_6 from "../images/i_6.png";
import with_modi from "../images/with_modi.png";
import left_button from "../images/leftarrow.png";
import right_button from "../images/rightarrow.png";
import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";
import i_7 from "../images/h_18.png";
import i_8 from "../images/h_19.png";
import i_9 from "../images/h_20.png";
import i_10 from "../images/h_21.png";
import i_11 from "../images/h_22.png";
import i_12 from "../images/h_23.png";
import { useLanguage } from "../context/LanguageContext";
import translations from "../components/translation.js";

const Home = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = [
   
    {
      image: cover2,
      content: t.home_tagline,
    },
    {
      image: cover3,
      content: t.home_tagline2,
    },
    {
      image: cover4,
      content:t.home_tagline3,
    },
    {
      image: cover5,
      content: t.home_tagline4,
    },
  ];
  // Change slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  // Handle dot click
  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const cards = [
    {
      title: translations[language].cards.boosted_local_economies.title,
      description:
        translations[language].cards.boosted_local_economies.description,
    },
    {
      title: translations[language].cards.sustainable_practices_adoption.title,
      description:
        translations[language].cards.sustainable_practices_adoption.description,
    },
    {
      title: translations[language].cards.leadership_development.title,
      description:
        translations[language].cards.leadership_development.description,
    },
    {
      title: translations[language].cards.resilient_infrastructure.title,
      description:
        translations[language].cards.resilient_infrastructure.description,
    },
    {
      title: translations[language].cards.increased_civic_engagement.title,
      description:
        translations[language].cards.increased_civic_engagement.description,
    },
    {
      title: translations[language].cards.strengthened_social_harmony.title,
      description:
        translations[language].cards.strengthened_social_harmony.description,
    },
  ];

  const cardData = [
    [
      {
        year: "2000",
        title: t.cardData.y2000.title,
        description: t.cardData.y2000.description,
        image: i_2000,
      },
      {
        year: "2010",
        title: t.cardData.y2010.title,
        description: t.cardData.y2010.description,
        image: i_2001,
      },
      {
        year: "2011",
        title: t.cardData.y2011.title,
        description: t.cardData.y2011.description,
        image: i_3,
      },
      {
        year: "2015",
        title: t.cardData.y2015.title,
        description: t.cardData.y2015.description,
        image: i_4,
      },
      {
        year: "2016",
        title: t.cardData.y2016.title,
        description: t.cardData.y2016.description,
        image: i_5,
      },
      {
        year: "2017",
        title: t.cardData.y2017.title,
        description: t.cardData.y2017.description,
        image: i_6,
      },
      {
        year: "2018",
        title: t.cardData.y2018.title,
        description: t.cardData.y2018.description,
        image: i_7,
      },
      {
        year: "2019",
        title: t.cardData.y2019.title,
        description: t.cardData.y2019.description,
        image: i_8,
      },
      {
        year: "2020",
        title: t.cardData.y2020.title,
        description: t.cardData.y2020.description,
        image: i_9,
      },
      {
        year: "2021",
        title: t.cardData.y2021.title,
        description: t.cardData.y2021.description,
        image: i_10,
      },
      {
        year: "2022",
        title: t.cardData.y2023.title,
        description: t.cardData.y2023.description,
        image: i_11,
      },
      {
        year: "2023",
        title: t.cardData.y2024.title,
        description: t.cardData.y2024.description,
        image: i_12,
      },
    ],
  ];
  // const Card = ({ year, title, description, image }) => (
  //   <div className={styles.card}>
  //     <img src={image} alt={title} className={styles.cardImage} />
  //     <h3>{year}</h3>
  //     <h4>{title}</h4>
  //     <p>{description}</p>
  //   </div>
  // );

  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the items to display (3 columns and 2 rows)
  const itemsPerPage = 6;
  const startIndex = currentPage * itemsPerPage;
  const visibleCards = cardData[0].slice(startIndex, startIndex + itemsPerPage);

  const handleNext = () => {
    if (startIndex + itemsPerPage < cardData[0].length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const mobFlattenedCards = cardData.flat(); // Flatten the array
  const [mobCurrentIndex, setMobCurrentIndex] = useState(0); // Track the current card index

  const handleMobNext = () => {
    setMobCurrentIndex((mobPrevIndex) =>
      mobPrevIndex === mobFlattenedCards.length - 1 ? 0 : mobPrevIndex + 1
    );
  };

  const handleMobPrev = () => {
    setMobCurrentIndex((mobPrevIndex) =>
      mobPrevIndex === 0 ? mobFlattenedCards.length - 1 : mobPrevIndex - 1
    );
  };

  const [mcurrentIndex, msetCurrentIndex] = useState(0);

  const mhandlePrev = () => {
    msetCurrentIndex((mprevIndex) =>
      mprevIndex === 0 ? cards.length - 1 : mprevIndex - 1
    );
  };

  const mhandleNext = () => {
    msetCurrentIndex((mprevIndex) =>
      mprevIndex === cards.length - 1 ? 0 : mprevIndex + 1
    );
  };
  return (
    <div className={styles.outer}>
      <Header />
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${slides[activeIndex].image})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Social Media Icons */}
        <div className={styles.socialmedia}>
          <div className={styles.socialIcons}>
            <a
              href="https://www.facebook.com/share/k7dRVumPPVobTUG7/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" className={styles.icon} />
            </a>
            <a
              href="https://www.instagram.com/meghnabordikar/profilecard/?igsh=MXJxYzJqZnZ4ZWVmOA=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" className={styles.icon} />
            </a>
            <a
              href="https://youtube.com/@mlameghnabordikardidi?si=RPE6Veo_Jy4hi8t2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="YouTube" className={styles.icon} />
            </a>
            <a
              href="https://x.com/MeghnaBordikar?t=IaPNJd19XTgT4PBpPTxBiA&s=08"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Twitter" className={styles.icon} />
            </a>
          </div>
        </div>

        {/* Main Content */}
        <div className={styles.overlayText}>
          "{slides[activeIndex].content}"
        </div>
        {/* Dots */}
        <div className={styles.dots}>
          {slides.map((_, index) => (
            <span
              key={index}
              className={`${styles.dot} ${
                index === activeIndex ? styles.active : ""
              }`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
      <div className={styles.midcontainer}>
        <div className={styles.namecontainer}>
          <div className={styles.rectangle}>{t.home}</div>
          <div className={styles.imagelogo}>
            <img src={client} alt="client" className={styles.client} />
          </div>
        </div>
        <div className={styles.lower}>
          <div className={styles.desclower}>
            <div className={styles.content}>
              <ul>
                <li>
                  <span> {t.edu}</span> {t.education_desc}
                </li>
                <li>
                  <span>{t.womenE}</span> {t.womenE_desc}
                </li>
                <li>
                  <span>{t.youth}</span> {t.youth_dev}
                </li>
                <li>
                  <span>{t.env_cons}</span> {t.env_cons_desc}
                </li>
                <li>
                  <span>{t.farmer}</span>
                  {t.farmer_desc}
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.imagelower}>
            <img src={meghna2} alt="client" className={styles.meghna2} />
          </div>
        </div>
      </div>
      <div className={styles.carouselContainer}>
        <div className={styles.carouselheading}>
          <div className={styles.namecontainer}>
            <div className={styles.rectangle} style={{ paddingLeft: "1vw" }}>
              {t.home2}
            </div>
            <div className={styles.imagelogo}>
              <img src={client} alt="client" className={styles.client} />
            </div>
          </div>
          <div className={styles.navButtons}>
            <button
              className={styles.navButton}
              onClick={handlePrevious}
              disabled={currentPage === 0}
            >
              &lt;
            </button>
            <button
              className={styles.navButton}
              onClick={handleNext}
              disabled={startIndex + itemsPerPage >= cardData[0].length}
            >
              &gt;
            </button>
          </div>
        </div>

        <div className={styles.cardsGrid}>
          {visibleCards.map((card, index) => (
            <div key={index} className={styles.card}>
              <img
                src={card.image}
                alt={card.title}
                className={styles.cardImage}
              />
              <h3>{card.year}</h3>
              <h4>{card.title}</h4>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
        <div className={styles.mobileCarousel}>
          {mobFlattenedCards.map((card, index) => (
            <div
              key={index}
              className={`${styles.mobileCard} ${
                mobCurrentIndex === index
                  ? styles.activeCard
                  : styles.hiddenCard
              }`}
            >
              {mobCurrentIndex === index && ( // Render content only for the active card
                <>
                  <img
                    src={card.image}
                    alt={card.title}
                    className={styles.mobileImage}
                  />
                  <div className={styles.mobileCardContent}>
                    <h2 className={styles.mobileYear}>{card.year}</h2>
                    <h3 className={styles.mobileTitle}>{card.title}</h3>
                    <p className={styles.mobileDescription}>
                      {card.description}
                    </p>
                  </div>
                </>
              )}
            </div>
          ))}

          <div className={styles.mobileNavigation}>
            <img
              src={left_button}
              alt="button"
              onClick={handleMobPrev}
              className={styles.mobileNavButton}
            />

            <img
              src={right_button}
              alt="button"
              onClick={handleMobNext}
              className={styles.mobileNavButton}
            />
          </div>
        </div>
      </div>
      <div className={styles.midcontainer}>
        <div className={styles.namecontainer}>
          <div className={styles.rectangle}>{t.home3}</div>
          <div className={styles.imagelogo}>
            <img src={client} alt="client" className={styles.client} />
          </div>
        </div>
        <div className={styles.low} style={{ border: "none" }}>
          <div className={styles.modi_con}>
            <img src={with_modi} alt="client" className={styles.with_modi} />
          </div>
          <div className={styles.con}>
            <div className={styles.gridContainer}>
              {cards.map((card, index) => (
                <div key={index} className={styles.gridCard}>
                  <h3 className={styles.gridTitle}>{card.title}</h3>
                  <p className={styles.gridDescription}>{card.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.mcarouselContainer}>
          <div className={styles.mcard}>
            <h2 className={styles.mcardTitle}>{cards[mcurrentIndex].title}</h2>
            <p className={styles.mcardDescription}>
              {cards[mcurrentIndex].description}
            </p>
          </div>
          <div className={styles.mobileNavigation}>
            <img
              src={left_button}
              alt="button"
              onClick={mhandlePrev}
              className={styles.mobileNavButton}
            />
            <img
              src={right_button}
              alt="button"
              onClick={mhandleNext}
              className={styles.mobileNavButton}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Home;
