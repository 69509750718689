import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import style from "./viewevent.module.css"
import { motion, AnimatePresence } from 'framer-motion';

const EventDetails = () => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const date = searchParams.get("date");
  const [eventDetails, setEventDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await fetch(
          `https://www.meghnabordikar.com/upload/api/events?name=${encodeURIComponent(name)}&date=${encodeURIComponent(date)}`
        );
        if (!response.ok) {
          throw new Error("Event details not found");
        }
        const data = await response.json();
        setEventDetails(data);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching event details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [name, date]);

  const hadnleView = (e) => {
    e.stopPropagation();
    fetch(selectedImage)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${name}.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Download failed', error));
  }

  if (loading) {
    return <p>Loading event details...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // Ensure location and content are available from the first event
  const firstEvent = eventDetails[0];

  return (
    <div className={style.container}>
      <div>
      <h2><span style={{ fontWeight: 600 }}>Event:</span> {name}</h2>
      <p><span  className={style.boldLarge}>Date:</span>  {new Date(date).toLocaleDateString("en-GB")}</p>
      {/* Display location and content once */}
      {firstEvent && (
        <>
          {firstEvent.location && <p><span className={style.boldLarge}>Location:</span>  {firstEvent.location}</p>}
          {firstEvent.content && <p><span className={style.boldLarge}>Content:</span>  {firstEvent.content}</p>}
        </>
      )}
      </div>
      {eventDetails.length > 0 ? (
        <div className={style.imagecon}
  
>
          {eventDetails.map((event, index) => (
            <div key={event._id}>
              {event.path && (
                <img
                  src={`https://www.meghnabordikar.com/${event.path.replace(/\\/g, '/')}`}
                  alt={`Event ${index}`}
                  className={style.imagecover}
                  onClick={() => {setSelectedImage(`https://www.meghnabordikar.com/${event.path.replace(/\\/g, '/')}`)}}
                />
              )}

              {selectedImage && (
              <AnimatePresence>
                <div
                  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
                  onClick={() => setSelectedImage(null)}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8, y: 0 }}
                    animate={{ opacity: 1, scale: 1, y: 0 }}
                    exit={{ opacity: 0, scale: 0.8, y: 0 }}
                    transition={{
                      type: 'tween',
                      duration: 0.2,
                      ease: 'easeIn',
                    }}
                    className="relative"
                  >
                    <img
                      src={selectedImage}
                      alt="Selected"
                      className="max-h-[60vh] rounded-md"
                      onClick={(e) => {e.stopPropagation()}}
                    />
                    <button
                      className="absolute top-[-3vw] right-0 text-white bg-black border-2 border-white h-[2.5vw] w-[2.5vw] text-[0.9vw] font-semibold flex justify-center items-center rounded-full"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedImage(null);
                      }}
                    >
                      ✕
                    </button>
                    <button
                      className="absolute top-[-3vw] right-[4vw] text-white bg-black border-2 border-white h-[2.5vw] w-fit px-[3vw] text-[1.5vw] flex flex-row justify-center items-center rounded-full"
                      title="Click to download"
                      onClick={(e) => {hadnleView(e)}}
                    >
                      Download ⬇
                    </button>
                  </motion.div>
                </div>
              </AnimatePresence>
            )}
            </div>
          ))}
        </div>
      ) : (
        <p>No event images available.</p>
      )}
    </div>
  );
};

export default EventDetails;
