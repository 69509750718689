import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./admin_login.module.css";
import { useNavigate } from "react-router-dom";
import admin from "../images/admin.png";
import logo from "../images/header_logo.png";
import Header from '../components/Header/Header.js';
import Footer from "../components/Footer/Footer.js";

 
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [nav, setNav] = useState(false)
 
  const navigate = useNavigate(); // React Router hook

  // useEffect(() => {
  //     window.scrollTo(0, 0);
  // }, []);
  
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     console.log("Navigating to /uploadpic...");
  //     navigate("/uploadpic");
  //   }
  // }, [nav]);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
 
    try {
      const response = await axios.post("https://www.meghnabordikar.com/auth/token", {
        email,
        password,
      });
 
      // Save the token in localStorage
      const token = response.data.token;
      if (token) {
        localStorage.setItem("token", token);
        console.log("Token stored:", localStorage.getItem("token"));
        alert("Login Successful!");
 
        // Navigate after ensuring the token is stored
      setTimeout(() => navigate("/uploadpic"), 1000);
      } else {
        throw new Error("Token not received from server");
      }
    } catch (err) {
      localStorage.removeItem("token");
      setError(err.response?.data?.message || "Login failed. Please try again.");
    }
  };
 
  return (
    <div className={styles.outer}>
      <Header/>
    <div className={styles.container}>
      {/* Left Section */}
      <div className={styles.imageSection}>
        <img
          src={admin}
          alt="Celebration"
          className={styles.image}
        />
      </div>
 
      {/* Right Section */}
      <div className={styles.formSection}>
        <div className={styles.logo}>
          <img
            src={logo}
            alt="Logo"
            className={styles.logoImage}
          />
          <div className={`${styles.namem} cursor-pointer`} onClick={() => {navigate("/");}}>Meghna Sakore Bordikar</div>
        </div>
        <h3 className={styles.formHeading}>Admin Credentials</h3>
        <form className={styles.form} onSubmit={handleSubmit}>
          <label className={styles.label}>Enter Email</label>
          <input
            type="text"
            placeholder="Enter Email"
            className={styles.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className={styles.label}>Enter Password</label>
          <input
            type="password"
            placeholder="Enter Password"
            className={styles.input}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className={styles.rememberMe}>
            <input type="checkbox" id="rememberMe" />
            <label htmlFor="rememberMe">Remember me</label>
          </div>
          {error && <p className={styles.error}>{error}</p>}
          <button type="submit" className={styles.loginButton}>
            LOG IN
          </button>
        </form>
      </div>
    </div>
    <Footer/>
    </div>
  );
};
 
export default LoginPage;