import React, { useEffect } from "react";
import styles from "./about.module.css"; // CSS Module
import client from "../images/circle.png";
import meghna from "../images/meghna.png";
import facebook from "../images/Facebook.png";
import instagram from "../images/Instagram.png";
import twitter from "../images/Twitter.png";
import youtube from "../images/YouTube.png";
import team from "../images/team.png";
import meghna2 from "../images/meghna2.png";
import axios from "axios";
import { useState, useNavigate } from "react";
import { Link } from "react-router-dom";
import Header from '../components/Header/Header.js';
import Footer from '../components/Footer/Footer.js'
import { useLanguage } from '../context/LanguageContext';
import translations from '../components/translation.js';
const About = () => {
  const { language } = useLanguage();
  const t = translations[language];
  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  //   const navigate = useNavigate();
  //   const navigatePath = (path) => {
  //     navigate(path);
  //   };

  return (
    <div>
      <Header/>
    <div className={styles.outer}>
      
      <div className={styles.namecontainer}>
        <div className={styles.rectangle}>
        {t.about}        </div>
        <div className={styles.imagelogo}>
          <img src={client} alt="client" className={styles.client} />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.imageSection}>
          <img src={meghna} alt="client" className={styles.meghna} />
        </div>
        <div className={styles.descContainer}>
          <div className={styles.head}>
          {t.visionaryLeader}          </div>
          <div className={styles.description}>
            {t.aboutdesc1}{" "}
          </div>
          <div className={styles.socialmedia}>
            <div className={styles.socialIcons}>
              <a
                href="https://www.facebook.com/share/k7dRVumPPVobTUG7/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" className={styles.icon} />
              </a>
              <a
                href="https://www.instagram.com/meghnabordikar/profilecard/?igsh=MXJxYzJqZnZ4ZWVmOA=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Instagram" className={styles.icon} />
              </a>
              <a
                href="https://youtube.com/@mlameghnabordikardidi?si=RPE6Veo_Jy4hi8t2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="YouTube" className={styles.icon} />
              </a>
              <a
                href="https://x.com/MeghnaBordikar?t=IaPNJd19XTgT4PBpPTxBiA&s=08"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Twitter" className={styles.icon} />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Updated container2 */}
      <div className={styles.middlesec}>
        <div className={styles.container2}>
          <div className={styles.superhead}>{t.about_background}</div>
          <div className={styles.head2}>{t.about_legacy}</div>
          <div className={styles.description2}>
            {t.about_desc2}
          </div>
        </div>
      </div>
      <div className={styles.lowermiddle}>
        <div className={styles.head3}>{t.belief}</div>
        <div className={styles.head4}>
          “{t.about_tag}”
        </div>
        <div className={styles.description3}>
          <p>
            {t.about_p1}
          </p>
          <ul>
            <li>
              {t.about_li1}
            </li>
            <li>
              {t.about_li2}
            </li>
            <li>
              {t.about_li3}
            </li>
          </ul>
          <p>
           {t.about_p2}
          </p>
        </div>
        <div className={styles.teamimage}>
          <img src={team} alt="client" className={styles.team} />
        </div>
      </div>
      <div className={styles.lower}>
      <div className={styles.desclower}>
        <div className={styles.content}>
      <div className={styles.head3}>{t.contributions_heading}</div>
      <p>
{t.contributions_intro}    </p>
    <ul>
      <li>
        <span style={{ fontWeight: 600 }}>{t.Cleanliness_Drives}:</span>{t.Cleanliness_Drives_desc} 
      </li>
      <li>
        <span style={{ fontWeight: 600 }}>{t.Water_Conservation}:</span>{t.water_cons_desc}
      </li>
      <li>
        <span style={{ fontWeight: 600 }}>{t.WomenandYouthEmpowerment}:</span>{t.WomenandYouthEmpowerment_desc}
      </li>
      <li>
        <span style={{ fontWeight: 600 }}>{t.Addiction_Recovery}:</span> {t.Addiction_Recovery_desc}
      </li>
      <li>
        <span style={{ fontWeight: 600 }}>{t.Community_Engagement}:</span> {t.Community_Engagement_desc}
      </li>
    </ul>
    <p style={{marginTop:"2%"}}>
{t.about_last}    </p>
      </div>
      </div>
      <div className={styles.imagelower}>
          <img src={meghna2} alt="client" className={styles.meghna2} />
        </div>
      
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default About;
