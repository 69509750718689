// import logo from './logo.svg';
import './App.css';
import Social from './pages/Social/Social.js'
import Gallery from './pages/Gallery/Gallery.js';
import UploadPicture from './pages/UploadPicture/UploadPicture.js';
import About from './pages/about';
import Home from './pages/home';
import { BrowserRouter as Router, Routes, Route ,Navigate} from "react-router-dom";
import Contact from './pages/contact';
import News from './pages/news';
import UploadEvent from './pages/uploadevent.js';
import LoginPage from './pages/admin_login.js';
import UploadPage from './pages/upload.js';
import CoverEvent from './pages/coverevent.js';
import EventDetails from './components/viewEvent.js';
import UploadPress from "./pages/UploadPress/UploadPress.js";
import Press from './pages/press.js';


function App() {
  const isAuthenticated = () => {
    console.log("token fetching")
    const token = localStorage.getItem("token");
    console.log("token fetched", token)
    return !!token; // Returns true if token exists, false otherwise
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/social" element={<Social />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />7
          <Route path="/press" element={<Press />} />
          <Route path="/event" element={<CoverEvent />} />
          <Route path="/events" element={<EventDetails />} />

          <Route path="/admin" element={<LoginPage />} />
          <Route path="/uploadpic" element={<UploadPicture />} />
          {/* <Route path="/uploadevent" element={<UploadEvent />} />
          <Route path="/uploadpress" element={<UploadPress />} /> */}
          {/* <Route
          path="/upload"
          element={
            isAuthenticated() ? <UploadPage /> : <Navigate to="/admin" />
          }
        /> */}
          {/* <Route
          path="/uploadevent"
          element={
            isAuthenticated() ? <UploadEvent /> : <Navigate to="/admin" />
          }
        /> */}
        <Route
          path="/uploadpic"
          element={
            isAuthenticated() ? <UploadPicture /> : <Navigate to="/admin" />
          }
        />
                  {/* <Route path="/uploadpic" element={<UploadPicture />} /> */}
                  <Route path="/uploadevent" element={<UploadEvent />} />
                  <Route path="/uploadpress" element={<UploadPress />} />


          <Route path="/event" element={<CoverEvent />} />
          <Route path="/events" element={<EventDetails />} />
          {/* <Route
          path="/uploadpress"
          element={
            isAuthenticated() ? <UploadPress /> : <Navigate to="/admin" />
          }
        /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
