import React, { useState } from 'react'
import { useNavigate, useNavigation } from 'react-router-dom'

import logo from '../../images/header_logo.png'
import fb from '../../images/Facebook.png'
import insta from '../../images/Instagram.png'
import X from '../../images/Twitter.png'
import yt from '../../images/YouTube.png'
import { useLanguage } from '../../context/LanguageContext'
import translations from '../../components/translation.js'

const AdminFooter = () => {
    const { language } = useLanguage();
    const t = translations[language];
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleClearInput = (e) => {
        e.preventDefault();
        setEmail('');
        console.log('Subscription action:', email);
    };

    return (
        <div className='w-full h-fit bg-transparent font-poppins'>
            {/* D E S K T O P */}
            <div className='min-h-[31vw] w-full px-[2vw] pt-[4vw] bg-cor flex xxs:hidden tabPt:flex flex-col justify-start items-center text-white'>
                <div className='bg-white text-cor flex flex-row justify-between rounded-[0.4vw] shadow-cs1 py-[2.35vw] px-[7vw] w-[57vw]'>
                    <div className='text-[1.35vw] font-medium flex justify-center items-center'>{t.newsletter}</div>
                    <form className='border border-cor rounded-[0.3vw] p-[0.5vw] flex flex-row items-center justify-between' onSubmit={handleClearInput} action="">
                        <input 
                            className='ml-[1vw] h-[2.8vw] w-[13.5vw] text-[1vw] focus:outline-none text-black' 
                            type='email' 
                            placeholder={t.enter_email}
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button className='bg-cor text-white h-[2.8vw] w-[10vw] rounded-[0.2vw] text-[1.25vw]'>{t.suscribe}</button>
                    </form>
                </div>
                <div className='flex flex-row justify-between items-center h-[2vw] w-[59vw] mt-[2.75vw]'>
                    <div className='flex flex-row justify-between items-center gap-[4vw] text-[0.95vw] font-semibold'>
                        <div className='cursor-pointer' onClick={() => {navigate('/uploadpic')}}>{t.galleryUpload}</div>
                        <div className='cursor-pointer' onClick={() => {navigate('/uploadpress')}}>{t.pressUpload}</div>
                        <div className='cursor-pointer' onClick={() => {navigate('/uploadevent')}}>{t.eventUpload}</div>
                    </div>
                    <div className='flex flex-row justify-between items-center gap-[0.5vw]'>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/share/k7dRVumPPVobTUG7/"><img className='h-[1.85vw] bg-white rounded-full cursor-pointer hover:scale-[120%] transition duration-200' src={fb}/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/meghnabordikar/profilecard/?igsh=MXJxYzJqZnZ4ZWVmOA=="><img className='h-[1.85vw] bg-white rounded-full cursor-pointer hover:scale-[120%] transition duration-200' src={insta}/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/@mlameghnabordikardidi?si=RPE6Veo_Jy4hi8t2"><img className='h-[1.85vw] bg-white rounded-full cursor-pointer hover:scale-[120%] transition duration-200' src={yt}/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://x.com/MeghnaBordikar?t=IaPNJd19XTgT4PBpPTxBiA&s=08"><img className='h-[1.85vw] bg-white rounded-full cursor-pointer hover:scale-[120%] transition duration-200' src={X}/></a>
                    </div>
                </div>
                <div className='h-[0.2vw] w-[59vw] mt-[1vw] bg-white bg-opacity-20'></div>
                <div className='flex flex-row justify-between items-center w-[59vw] mt-[2.5vw] text-[0.95vw]'>
                    <div className='w-[15vw] opacity-70 text-start'>{t.copyright}</div>
                    <img src={logo} className='h-[4.25vw]'/>
                    <div className='flex flex-row justify-between items-center w-[25vw]'>
                        <div
                            onClick={() => navigate('/admin')}
                            className='cursor-pointer text-cor bg-white py-[0.5vw] px-[1vw] font-semibold rounded-[0.25vw] hidden'
                        >
                            {t.Admin_Panel}
                        </div>
                        <div>{t.service}</div>
                        <div>{t.pp}</div>
                    </div>
                </div>
            </div>

            {/* M O B I L E */}
            <div className='min-h-[80vw] w-full px-[2vw] pt-[4vw] bg-cor flex tabPt:hidden flex-col justify-start items-center text-white'>
                <div className='flex flex-row justify-center items-center gap-[2vw]'>
                    <img className='h-[8vw]' src={logo} />
                    <div className='font-poppinsMedium text-[3.5vw]'>
                        {t.name}
                    </div>
                </div>
                <div className='w-full my-[4vw] px-[5vw] gap-[2vw] flex flex-col justify-between'>
                    <div className='flex flex-row justify-between items-center gap-[4vw] text-[3vw] font-poppinsMedium'>
                        <div className='cursor-pointer' onClick={() => {navigate('/ ')}}>{t.f_home}</div>
                        <div className='cursor-pointer' onClick={() => {navigate('/about')}}>{t.f_about}</div>
                        <div className='cursor-pointer' onClick={() => {navigate('/social')}}>{t.f_sw}</div>
                        <div className='cursor-pointer' onClick={() => {navigate('/gallery')}}>{t.f_gallery}</div>
                        {/* <div className='cursor-pointer' onClick={() => {navigate('/press')}}>{t.blog}</div>
                        <div className='cursor-pointer' onClick={() => {navigate('/event')}}>{t.event}</div> */}
                    </div>
                    <div className='flex flex-row justify-center items-center gap-[4vw] text-[3vw] font-poppinsMedium'>
                        {/* <div className='cursor-pointer' onClick={() => {navigate('/ ')}}>{t.f_home}</div>
                        <div className='cursor-pointer' onClick={() => {navigate('/about')}}>{t.f_about}</div>
                        <div className='cursor-pointer' onClick={() => {navigate('/social')}}>{t.f_sw}</div>
                        <div className='cursor-pointer text-start' onClick={() => {navigate('/gallery')}}>{t.f_gallery}</div> */}
                        <div className='cursor-pointer w-[30%] min-w-fit' onClick={() => {navigate('/press')}}>{t.press}</div>
                        <div className='cursor-pointer w-[30%] min-w-fit' onClick={() => {navigate('/event')}}>{t.event}</div>
                    </div>
                </div>
                <div className='bg-white text-cor flex flex-col justify-between rounded-[1vw] shadow-cs1 py-[2.35vw] px-[5vw] w-[86vw]'>
                    <div className='text-[2.5vw] font-poppinsMedium flex justify-center items-center'>{t.newsletter}</div>
                    <form className='border border-cor rounded-[0.5vw] py-[1vw] px-[3vw] text-[2.5vw] w-full flex flex-row justify-between' onSubmit={handleClearInput} action="">
                        <input 
                            className='focus:outline-none text-[#2B3D51] placeholder:text-[#2B3D51]' 
                            type='email' 
                            placeholder={t.enter_email}
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button className='bg-cor text-white px-[2vw] py-[1vw] rounded-[0.5vw]'>{t.suscribe}</button>
                    </form>
                </div>
                <div className='flex flex-row justify-between items-center w-[86vw] py-[2.35vw] text-[3.5vw]'>
                    <div
                        onClick={() => navigate('/admin')}
                        className='cursor-pointer text-cor bg-white py-[0.5vw] px-[2vw] rounded-[1vw] font-semibold hidden'
                    >
                        {t.Admin_Panel}
                    </div>
                    <div>{t.service}</div>
                    <div>{t.pp}</div>
                </div>
                <div className='flex flex-col justify-between items-center h-fit w-full mt-[0vw] gap-[2.35vw] text-[3vw]'>
                    <div>{t.f_Follow}</div>
                    <div className='flex flex-row justify-between items-center gap-[2.5vw]'>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/share/k7dRVumPPVobTUG7/"><img className='h-[6vw] bg-white rounded-full cursor-pointer hover:scale-[120%] transition duration-200' src={fb}/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/meghnabordikar/profilecard/?igsh=MXJxYzJqZnZ4ZWVmOA=="><img className='h-[6vw] bg-white rounded-full cursor-pointer hover:scale-[120%] transition duration-200' src={insta}/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/@mlameghnabordikardidi?si=RPE6Veo_Jy4hi8t2"><img className='h-[6vw] bg-white rounded-full cursor-pointer hover:scale-[120%] transition duration-200' src={yt}/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://x.com/MeghnaBordikar?t=IaPNJd19XTgT4PBpPTxBiA&s=08"><img className='h-[6vw] bg-white rounded-full cursor-pointer hover:scale-[120%] transition duration-200' src={X}/></a>
                    </div>
                </div>
                {/* <div className='h-[0.2vw] w-[59vw] mt-[1vw] bg-white bg-opacity-20'></div> */}
                <div className='flex flex-row justify-center items-center w-full mt-[2.5vw] text-[3vw]'>
                    <div className='opacity-70 text-start'>{t.copyright}.</div>
                </div>
            </div>
        </div>
    )
}

export default AdminFooter