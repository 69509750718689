import React, { useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../context/LanguageContext';

import translations from '../../components/translation.js';
import classes from './Social.module.css'
import Header from '../../components/Header/Header.js'
import Footer from '../../components/Footer/Footer.js'
import sun from '../../images/circle.png'
import s1 from '../../images/social1.png'
import s2 from '../../images/social2.png'
import s3 from '../../images/social3.png'
import s4 from '../../images/social4.png'
import s5 from '../../images/social5.png'
import s6 from '../../images/social6.png'
import tree from '../../images/tree_bg.png'
import arrow from '../../images/uparrow.svg'
import prev from '../../images/leftarrow.png'
import next from '../../images/rightarrow.png'

const Social = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const carouselRef = useRef(null);
  const carouselRefMob = useRef(null);
  const dotRefs = useRef([]);
  const dotRefsMob = useRef([]);
  const edRef = useRef(null);
  const rdRef = useRef(null);
  const envRef = useRef(null);
  const ythRef = useRef(null);
  const fwRef = useRef(null);
  const womenRef = useRef(null);
  const edRefMob = useRef(null);
  const rdRefMob = useRef(null);
  const envRefMob = useRef(null);
  const ythRefMob = useRef(null);
  const fwRefMob = useRef(null);
  const womenRefMob = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  
  const items = [
    {
      title: t.combateve,
      description: t.combatevedesc,
    },
    {
      title: t.selfdef,
      description: t.selfdefdesc,
    },
    {
      title: t.hygienesanitation,
      description: t.hygienesanitationdesc,
    },
    {
      title: t.dropouts,
      description: t.dropoutsdesc,
    },
    {
      title: t.selfhelp,
      description: t.selfhelpdesc,
    },
    {
      title: t.criticalchallenges,
      description: t.criticalchallengesdesc,
    },
  ];
  
  const [bottomPosition, setBottomPosition] = useState('2vw');

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.getElementById('footer');
      const footerTop = footer.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      if (footerTop < viewportHeight - 20) {
        setBottomPosition(`${viewportHeight - footerTop + 20}px`);
      } else {
        setBottomPosition('2vw');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  
  const handleNext = () => {
    if (currentIndex < items.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (targetRef) => {
    if (targetRef.current) {
      const targetPosition = targetRef.current.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerWidth * 0.05;
      window.scrollTo({ top: targetPosition - offset, behavior: 'smooth' });
    }  
  }

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    // if(hash==='') {
    //   window.scrollTo(0, 0);
    // }
    const sectionRefs = {
      edSec: edRef,
      rdSec: rdRef,
      envSec: envRef,
      ythSec: ythRef,
      fwSec: fwRef,
      wSec: womenRef,
    };
  
    if (sectionRefs[hash]?.current) {
      const targetPosition = sectionRefs[hash].current.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerWidth * 0.05;
      window.scrollTo({ top: targetPosition - offset });
  
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    const sectionRefs = {
      edSecMob: edRefMob,
      rdSecMob: rdRefMob,
      envSecMob: envRefMob,
      ythSecMob: ythRefMob,
      fwSecMob: fwRefMob,
      wSecMob: womenRefMob,
    };
  
    if (sectionRefs[hash]?.current) {
      const targetPosition = sectionRefs[hash].current.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerWidth * 0.1;
      window.scrollTo({ top: targetPosition - offset });
  
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, []);
  
  const scrollToGroup = (index) => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.scrollWidth / 5;
      carouselRef.current.scrollTo({
        left: scrollAmount * index,
        behavior: 'smooth',
      });
    }
  };
  
  const updateActiveDot = () => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.scrollWidth / 6;
      const currentIndex = Math.floor(carouselRef.current.scrollLeft / scrollAmount);
      
      // Set the active dot by adding/removing bg-cor class
      dotRefs.current.forEach((dot, index) => {
        if (dot) {
          if (index === currentIndex) {
            dot.classList.add('bg-cor');
            dot.classList.remove('bg-gray-200');
          } else {
            dot.classList.add('bg-gray-200');
            dot.classList.remove('bg-cor');
          }
        }
      });
    }
  };

  const scrollToGroupMob = (index) => {
    if (carouselRefMob.current) {
      const scrollAmount = carouselRefMob.current.scrollWidth / 9;
      carouselRefMob.current.scrollTo({
        left: scrollAmount * index,
        behavior: 'smooth',
      });
    }
  };
  
  const updateActiveDotMob = () => {
    if (carouselRefMob.current) {
      const scrollAmount = carouselRefMob.current.scrollWidth / 10;
      const currentIndex = Math.floor(carouselRefMob.current.scrollLeft / scrollAmount);
      
      // Set the active dot by adding/removing bg-cor class
      dotRefsMob.current.forEach((dot, index) => {
        if (dot) {
          if (index === currentIndex) {
            dot.classList.add('bg-cor');
            dot.classList.remove('bg-gray-200');
          } else {
            dot.classList.add('bg-gray-200');
            dot.classList.remove('bg-cor');
          }
        }
      });
    }
  };

  useEffect(() => {
    updateActiveDot();

    const handleScroll = () => {
      updateActiveDot();
    };

    const carouselElement = carouselRef.current;
    carouselElement?.addEventListener('scroll', handleScroll);

    return () => {
      carouselElement?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  useEffect(() => {
    updateActiveDotMob();

    const handleScrollMob = () => {
      updateActiveDotMob();
    };

    const carouselElementMob = carouselRefMob.current;
    carouselElementMob?.addEventListener('scroll', handleScrollMob);

    return () => {
      carouselElementMob?.removeEventListener('scroll', handleScrollMob);
    };
  }, []);

  return (
    <div className='relative'>
        <AnimatePresence>
          <Header/>
          {/* D E S K T O P */}
          <div className='h-fit pt-[8vw] pb-[6.5vw] font-poppins select-none flex flex-col xxs:hidden tabPt:flex'>
            {isVisible && (
              <motion.div
                onClick={handleScrollToTop}
                initial={{ opacity: 0, scale: 1, y: 20 }}
                animate={{ opacity: 1, scale: 1, y: 0 }} // Add bounce effect in the y-axis
                exit={{ opacity: 0, scale: 1, y: 20, transition: { duration: 0.1 } }}
                transition={{
                  type: 'spring',
                  stiffness: 500, // Increased stiffness for a snappier spring
                  damping: 10,    // Reduced damping for more bounce
                  // mass: 0.5,      // Lower mass for quicker movement
                  duration: 0.5,    // Controls how long the bounce animation lasts
                }}
                className="fixed bottom-[2vw] right-[1vw] bg-transparent flex flex-col justify-center items-center select-none cursor-pointer"
                style={{ bottom: bottomPosition }}
              >
                <div className='h-[2.75vw] w-[2.75vw] bg-cor text-white shadow-cs2 flex flex-col justify-center items-center relative text-[2vw] font-garamond font-semibold' title="Click to scroll up">
                  ^
                </div>
              </motion.div>
            )}
            <div className='h-fit w-full pt-[2vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col justify-between items-center w-full p-[1.65vw] gap-[1.65vw] shadow-cs1 rounded-[0.5vw] text-[1.3vw] font-semibold'>
                <div className='flex flex-row justify-between items-center gap-[4vw]'>
                  <div className='cursor-pointer select-none text-cor' onClick={() => {scrollToSection(edRef)}}>{t.education}</div>
                  <div className='cursor-pointer select-none' onClick={() => {scrollToSection(rdRef)}}>{t.ruraltopic}</div>
                  <div className='cursor-pointer select-none' onClick={() => {scrollToSection(envRef)}}>{t.environment}</div>
                  <div className='cursor-pointer select-none' onClick={() => {scrollToSection(ythRef)}}>{t.youthtopic}</div>
                </div>
                <div className='flex flex-row justify-between items-center gap-[4vw]'>
                  <div className='cursor-pointer select-none' onClick={() => {scrollToSection(fwRef)}}>{t.farmerwelfare}</div>
                  <div className='cursor-pointer select-none' onClick={() => {scrollToSection(womenRef)}}>{t.womenempowerment}</div>
                </div>
              </div>
            </div>
            <div id='edSec' ref={edRef} className='h-fit w-full pt-[4.25vw] px-[4.8vw] flex flex-col justify-between items-center'>
              {/* <motion.div
                initial={{ scale: 1, x: 500, opacity: 0, transition: { duration: 0.4 } }} // Start small, off-screen to the left
                animate={{ scale: 1, x: 0, opacity: 1, transition: { duration: 0.4 } }} // Grow to full size, slide into view
                transition={{
                  type: 'spring',
                  stiffness: 100,
                  damping: 20,
                  duration: 0.5, // Control the animation duration
                }}
                className="flex flex-row justify-between items-center bg-cor w-full rounded-[1.25vw]"
              >
                <div className="w-fit py-[1.5vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[1.6vw] tracking-wider font-semibold">
                  {t.mission}
                </div>
                <div className="flex items-center rounded-l-[2.9vw] h-full flex-grow px-[2.5vw] pl-0 bg-white">
                  <img className="h-[6vw]" src={sun} />
                </div>
              </motion.div> */}
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.25vw]'>
                <div className='w-fit py-[1.5vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[1.6vw] tracking-wider font-semibold'>{t.mission}</div>
                <div className='flex items-center rounded-l-[2.9vw] h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[6vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[2.75vw] p-[1.65vw] pb-[4vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[1.75vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[1.6vw] opacity-65'>
                  {t.edvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full pt-[3vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center w-full gap-[2vw]'>
                <div className='flex flex-col justify-between items-center w-fit p-[1.65vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] font-semibold border border-cor shadow-cs4'>
                  <img className='h-[36.5vw] w-[33vw] rounded-[0.5vw]' src={s1}/>
                </div>
                <div className='flex flex-col justify-start items-center w-[50vw] h-[39.8vw] pt-[1vw]'>
                  <div className='text-[1vw]'>{t.believes}</div>
                  <div className='font-poppinsBold tracking-wide leading-[3vw] text-[1.5vw] py-[1.5vw]'>{t.quote}</div>
                  <div className='text-justify text-[0.95vw] leading-[3.2vw] min-h-fit'>
                    {t.quotedesc}
                    <ul className='list-outside list-disc ml-[2vw]'>
                      <li><span className='font-poppinsMedium'>{t.campus}</span> {t.campusdesc}</li>
                      <li><span className='font-poppinsMedium'>{t.collabit}</span> {t.collabitdesc}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='h-fit w-full pt-[2.8vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col justify-between items-center w-full p-[1.5vw] pb-[1.7vw] gap-[1.25vw] rounded-[0.5vw] border border-cor shadow-cs2 bg-black bg-opacity-[0.03]'>
                <div className='flex flex-row justify-between items-center text-[1.6vw] font-poppinsSemiBold'>{t.chidimaar}</div>
                <div className='flex flex-row justify-between items-center text-[1.075vw] font-poppinsSemiBold opacity-65'>
                  {t.chidimaardesc}
                </div>
              </div>
              <div className='h-[35vw] w-full mt-[2.8vw] mb-[1vw] px-[4.8vw] grid grid-cols-3 gap-[2vw]'>
                <div className='h-full flex flex-col justify-center items-center border border-cor shadow-cs2 rounded-[0.5vw] px-[1vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] hover:scale-[110%] transition duration-200'>
                  <div className='flex flex-row justify-between items-center text-[1.25vw] font-poppinsSemiBold'>{t.combateve}</div>
                  <div className='flex flex-row justify-between items-center text-[1vw] font-poppinsMedium leading-[2.5vw]'>{t.combatevedesc}</div>
                </div>
                <div className='h-full flex flex-col justify-center items-center border border-cor shadow-cs2 rounded-[0.5vw] px-[1vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] hover:scale-[110%] transition duration-200'>
                  <div className='flex flex-row justify-between items-center text-[1.25vw] font-poppinsSemiBold'>{t.selfdef}</div>
                  <div className='flex flex-row justify-between items-center text-[1vw] font-poppinsMedium leading-[2.5vw]'>{t.selfdefdesc}</div>
                </div>
                <div className='h-full flex flex-col justify-center items-center border border-cor shadow-cs2 rounded-[0.5vw] px-[1vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] hover:scale-[110%] transition duration-200'>
                  <div className='flex flex-row justify-between items-center text-[1.25vw] font-poppinsSemiBold'>{t.hygienesanitation}</div>
                  <div className='flex flex-row justify-between items-center text-[1vw] font-poppinsMedium leading-[2.5vw]'>{t.hygienesanitationdesc}</div>
                </div>
                <div className='h-full flex flex-col justify-center items-center border border-cor shadow-cs2 rounded-[0.5vw] px-[1vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] hover:scale-[110%] transition duration-200'>
                  <div className='flex flex-row justify-between items-center text-[1.25vw] font-poppinsSemiBold'>{t.dropouts}</div>
                  <div className='flex flex-row justify-between items-center text-[1vw] font-poppinsMedium leading-[2.5vw]'>{t.dropoutsdesc}</div>
                </div>
                <div className='h-full flex flex-col justify-center items-center border border-cor shadow-cs2 rounded-[0.5vw] px-[1vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] hover:scale-[110%] transition duration-200'>
                  <div className='flex flex-row justify-between items-center text-[1.25vw] font-poppinsSemiBold'>{t.selfhelp}</div>
                  <div className='flex flex-row justify-between items-center text-[1vw] font-poppinsMedium leading-[2.5vw]'>{t.selfhelpdesc}</div>
                </div>
                <div className='h-full flex flex-col justify-center items-center border border-cor shadow-cs2 rounded-[0.5vw] px-[1vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] hover:scale-[110%] transition duration-200'>
                  <div className='flex flex-row justify-between items-center text-[1.25vw] font-poppinsSemiBold'>{t.criticalchallenges}</div>
                  <div className='flex flex-row justify-between items-center text-[1vw] font-poppinsMedium leading-[2.5vw]'>{t.criticalchallengesdesc}</div>
                </div>
              </div>
            </div>
            <div id='rdSec' ref={rdRef} className='h-fit w-full pt-[3vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.25vw]'>
                <div className='w-fit py-[1.5vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[1.6vw] tracking-wider font-semibold'>{t.ruraldev}</div>
                <div className='flex items-center rounded-l-[2.9vw] h-full w-[48vw] px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[6vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[2.75vw] p-[1.65vw] pb-[4vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[1.75vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[1.6vw] opacity-65'>
                  {t.rdvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full pt-[4.25vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <img className='w-full' src={s2}/>
            </div>
            <div className='h-fit w-full pt-[2.8vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col justify-between items-center w-full p-[1.5vw] pb-[1.7vw] gap-[1.25vw] rounded-[0.5vw] border border-cor shadow-cs2 bg-black bg-opacity-[0.03]'>
                <div className='flex flex-row justify-between items-center text-[1.6vw] font-poppinsSemiBold'>{t.keyfocus}</div>
              </div>
              <div className={`h-[22vw] w-full mt-[2.7vw] flex flex-row gap-[2vw] overflow-x-auto overflow-y-hidden snap-x snap-mandatory scroll-smooth ${classes.noScroll}`} ref={carouselRef}>
                <div className='h-full w-[36vw] flex flex-col justify-start items-start border border-cor rounded-[0.5vw] px-[1.75vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[1.25vw] font-poppinsSemiBold'>{t.primaryed}</div>
                  <div className='flex flex-row justify-start items-center text-[1vw] font-poppinsMedium leading-[3vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium'>
                      <li>{t.primaryed1}</li>
                      <li>{t.primaryed2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[36vw] flex flex-col justify-start items-start border border-cor rounded-[0.5vw] px-[1.75vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[1.25vw] font-poppinsSemiBold'>{t.agriculturaladv}</div>
                  <div className='flex flex-row justify-start items-center text-[1vw] font-poppinsMedium leading-[3vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium'>
                      <li>{t.agriculturaladv1}</li>
                      <li>{t.agriculturaladv2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[36vw] flex flex-col justify-start items-start border border-cor rounded-[0.5vw] px-[1.75vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[1.25vw] font-poppinsSemiBold'>{t.digitalfinance}</div>
                  <div className='flex flex-row justify-start items-center text-[1vw] font-poppinsMedium leading-[3vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium'>
                      <li>{t.digitalfinance1}</li>
                      <li>{t.digitalfinance2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[36vw] flex flex-col justify-start items-start border border-cor rounded-[0.5vw] px-[1.75vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[1.25vw] font-poppinsSemiBold'>{t.literacy}</div>
                  <div className='flex flex-row justify-start items-center text-[1vw] font-poppinsMedium leading-[3vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.literacy1}</li>
                      <li>{t.literacy2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[36vw] flex flex-col justify-start items-start border border-cor rounded-[0.5vw] px-[1.75vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[1.25vw] font-poppinsSemiBold'>{t.infrastructure}</div>
                  <div className='flex flex-col justify-start items-center text-[1vw] font-poppinsMedium leading-[3vw]'>
                    {t.infrastructure1}
                    <ul className='list-outside list-disc ml-[-4vw] text-start'>
                      <li>{t.infrastructure2}</li>
                      <li>{t.infrastructure3}</li>
                      <li>{t.infrastructure4}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[36vw] flex flex-col justify-start items-start border border-cor rounded-[0.5vw] px-[1.75vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[1.25vw] font-poppinsSemiBold'>{t.scientific}</div>
                  <div className='flex flex-row justify-start items-center text-[1vw] font-poppinsMedium leading-[3vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.scientific1}</li>
                      <li>{t.scientific2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[36vw] flex flex-col justify-start items-start border border-cor rounded-[0.5vw] px-[1.75vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[1.25vw] font-poppinsSemiBold'>{t.health}</div>
                  <div className='flex flex-row justify-start items-center text-[1vw] font-poppinsMedium leading-[3vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.health1}</li>
                      <li>{t.health2}</li>
                      <li>{t.health3}</li>
                      <li>{t.health4}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[36vw] flex flex-col justify-start items-start border border-cor rounded-[0.5vw] px-[1.75vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[1.25vw] font-poppinsSemiBold'>{t.jobcreation}</div>
                  <div className='flex flex-row justify-start items-center text-[1vw] font-poppinsMedium leading-[3vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.jobcreation1}</li>
                      <li>{t.jobcreation2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[36vw] flex flex-col justify-start items-start border border-cor rounded-[0.5vw] px-[1.75vw] pt-[2vw] pb-[1.3vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[1.25vw] font-poppinsSemiBold'>{t.farmers}</div>
                  <div className='flex flex-row justify-start items-center text-[1vw] font-poppinsMedium leading-[3vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.farmers1}</li>
                      <li>{t.farmers2}</li>
                      <li>{t.farmers3}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="dots-container flex justify-center mt-[2.5vw]">
                {[...Array(5)].map((_, index) => (
                  <button
                    key={index}
                    ref={(el) => (dotRefs.current[index] = el)}
                    className="dot w-[1.5vw] h-[1.5vw] rounded-full mx-[0.5vw] my-[1vw] bg-gray-200"
                    onClick={() => scrollToGroup(index)}
                  />
                ))}
              </div>
            </div>
            <div id='envSec' ref={envRef} className='h-fit w-full pt-[2.8vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.25vw]'>
                <div className='w-fit py-[1.5vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[1.6vw] tracking-wider font-semibold'>{t.sustainability}</div>
                <div className='flex items-center rounded-l-[2.9vw] h-full w-[48vw] px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[6vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[2.75vw] pt-[1.65vw] px-[4.5vw] pb-[4vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[1.75vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[1.6vw] opacity-65'>
                  {t.sustainabilityvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center w-full mt-[2.75vw] p-[3vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 transition duration-200'>
                <div className='flex flex-col items-start justify-center text-start text-[0.95vw] leading-[2.5vw]'>
                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.initiatives}</span>
                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.greendrives}</span>
                  <ul className='list-outside list-disc ml-[2vw] text-start font-poppins'>
                    <li>{t.greendrives1}</li>
                    <li>{t.greendrives2}</li>
                    <li>{t.greendrives3}</li>
                  </ul>

                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.waterconservation}</span>
                  <ul className='list-outside list-disc ml-[2vw] text-start font-poppins'>
                    <li>{t.waterconservation1}</li>
                    <li>{t.waterconservation2}</li>
                  </ul>
                </div>
                <img className='h-[22vw] rounded-[0.5vw]' src={s3}/>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='mt-[2.75vw] relative'>
                <img className='h-[33vw]' src={tree}/>
                <div className='bg-black h-full w-full absolute top-0 opacity-20 rounded-[0.5vw]'></div>
                <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-between items-center w-[77vw] p-[3vw] gap-[1.25vw] rounded-[0.5vw] shadow-cs2 bg-white bg-opacity-[0.8]'>
                  <div className='flex flex-row justify-between items-center text-[1.6vw] font-poppinsSemiBold'>{t.achievement}</div>
                  <div className='flex flex-row justify-between items-center text-[1.075vw] font-poppins'>
                    <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium text-[0.95vw] leading-[2vw]'>
                      <li>{t.achievement1}</li>
                      <li>{t.achievement2}</li>
                      <li>{t.achievement3}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id='ythSec' ref={ythRef} className='h-fit w-full pt-[4.25vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.25vw]'>
                <div className='w-fit py-[1.5vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[1.6vw] tracking-wider font-semibold'>{t.youthdev}</div>
                <div className='flex items-center rounded-l-[2.9vw] h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[6vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[2.75vw] p-[1.65vw] pb-[4vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[1.75vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[1.6vw] opacity-65 px-[2.5vw]'>
                  {t.youthvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center w-full mt-[2.75vw] p-[3vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 transition duration-200'>
                <div className='flex flex-col items-start justify-center text-start text-[0.95vw] leading-[3vw]'>
                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.initiatives}</span>
                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.edsupp}</span>
                  <ul className='list-outside list-disc ml-[2vw] text-start font-poppins'>
                    <li>{t.edsupp1}</li>
                    <li>{t.edsupp2}</li>
                  </ul>
                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.skilldev}</span>
                  <ul className='list-outside list-disc ml-[2vw] text-start font-poppins'>
                    <li>{t.skilldev1}</li>
                    <li>{t.skilldev2}</li>
                  </ul>
                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.community}</span>
                  <ul className='list-outside list-disc ml-[2vw] text-start font-poppins'>
                    <li>{t.community1}</li>
                    <li>{t.community2}</li>
                  </ul>
                </div>
                <img className='h-[32.5vw] rounded-[0.5vw]' src={s4}/>
              </div>
            </div>
            <div id='fwSec' ref={fwRef} className='h-fit w-full pt-[4.25vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.25vw]'>
                <div className='w-fit py-[1.5vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[1.6vw] tracking-wider font-semibold'>{t.farmersemp}</div>
                <div className='flex items-center rounded-l-[2.9vw] h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[6vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[2.75vw] p-[1.65vw] pb-[4vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[1.75vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[1.6vw] opacity-65 px-[3vw]'>
                  {t.farmervision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center w-full mt-[2.75vw] p-[3vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 transition duration-200'>
                <div className='flex flex-col items-start justify-center text-start text-[0.95vw] leading-[2.5vw]'>
                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.farmerinitiatives}</span>
                  <br/>
                  <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium'>
                    <li><span className='font-poppinsSemiBold'>{t.seminar}</span>{t.seminardesc}</li>
                    <li><span className='font-poppinsSemiBold'>{t.organic}</span>{t.organicdesc}</li>
                    <li><span className='font-poppinsSemiBold'>{t.rights}</span>{t.rightsdesc}</li>
                    <li><span className='font-poppinsSemiBold'>{t.growth}</span>{t.growthdesc}</li>
                  </ul>
                </div>
                <img className='h-[22vw] rounded-[0.5vw]' src={s5}/>
              </div>
            </div>
            <div id='wSec' ref={womenRef} className='h-fit w-full pt-[4.25vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.25vw]'>
                <div className='w-fit py-[1.5vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[1.6vw] tracking-wider font-semibold'>{t.womenemp}</div>
                <div className='flex items-center rounded-l-[2.9vw] h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[6vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[2.75vw] p-[1.65vw] pb-[4vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[1.75vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[1.6vw] opacity-65 px-[3vw]'>
                  {t.womenvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center w-full mt-[2.75vw] p-[3vw] gap-[2vw] rounded-[0.5vw] text-[1.3vw] border border-cor shadow-cs4 transition duration-200'>
                <div className='flex flex-col items-start justify-center text-start text-[0.95vw] leading-[2.25vw]'>
                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.initiatives}</span>
                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.anganwadi}</span>
                  <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium'>
                    <li>{t.anganwadi1}</li>
                    <li>{t.anganwadi2}</li>
                  </ul>

                  <span className='text-[1.05vw] font-poppinsSemiBold'>{t.selfhelpgroups}</span>
                  <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium'>
                    <li>{t.selfhelpgroups1}</li>
                    <li>{t.selfhelpgroups2}</li>
                  </ul>
                </div>
                <img className='h-[23.5vw] rounded-[0.5vw]' src={s6}/>
              </div>
            </div>
          </div>

          {/* M O B I L E */}
          <div className='h-fit pt-[12.5vw] pb-[6.5vw] font-poppins select-none flex flex-col tabPt:hidden'>
            <div id='edSecMob' ref={edRefMob} className='h-fit w-full pt-[5vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.75vw]'>
                <div className='w-fit py-[2vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[3.25vw] tracking-wider font-semibold'>{t.mission}</div>
                <div className='flex items-center rounded-l-full h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[14vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[6vw] py-[3.5vw] pb-[4vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[5vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[4.5vw] opacity-65 leading-[10vw] px-[5vw]'>
                  {t.edvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full pt-[7.5vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col justify-between items-center w-full gap-[2vw]'>
                <div className='flex flex-col justify-between items-center w-fit p-[3.5vw] gap-[2vw] rounded-[2vw] text-[1.3vw] font-semibold border border-cor shadow-cs4'>
                  <img className='w-full rounded-[0.5vw]' src={s1}/>
                </div>
                <div className='flex flex-col justify-start items-center w-full pt-[5vw]'>
                  <div className='text-[5vw]'>{t.believes}</div>
                  <div className='font-poppinsBold tracking-wide leading-[10vw] text-[5.75vw] py-[5vw]'>{t.quote}</div>
                  <div className='text-justify text-[3.75vw] leading-[8vw] px-[2vw]'>
                    {t.quotedesc}
                    <ul className='list-outside list-disc ml-[5vw]'>
                      <li><span className='font-poppinsMedium'>{t.campus}</span> {t.campusdesc}</li>
                      <li><span className='font-poppinsMedium'>{t.collabit}</span> {t.collabitdesc}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='h-fit w-full pt-[5vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col justify-between items-center w-full p-[5vw] gap-[1.25vw] rounded-[2vw] border border-cor shadow-cs2 bg-black bg-opacity-[0.03]'>
                <div className='flex flex-row justify-between items-center text-[5vw] font-poppinsSemiBold'>{t.chidimaar}</div>
                <div className='flex flex-row justify-between items-center text-[4.5vw] font-poppinsSemiBold opacity-65'>
                  {t.chidimaardesc}
                </div>
              </div>
              <div className='h-[50vw] flex flex-col justify-center items-center border border-cor shadow-cs2 rounded-[1vw] mt-[7.5vw] p-[5vw] gap-[1.25vw] transition duration-200'>
                <div className='flex flex-row justify-between items-center text-[4.5vw] font-poppinsSemiBold'>{items[currentIndex].title}</div>
                <div className='flex flex-row justify-between items-center text-[3.5vw] font-poppinsMedium leading-[10vw]'>{items[currentIndex].description}</div>
              </div>
              <div className='flex flex-row justify-between items-center mt-[5vw] w-full'>
                <svg onClick={handlePrev} className={`${currentIndex === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="3 3 28 28" fill="none">
                  <path fill={currentIndex === 0 ? '#A3A3A3' : '#E85112'} fillRule="evenodd" clipRule="evenodd" d="M17.4941 5.3125C23.7618 5.3125 28.8428 10.5452 28.8428 17C28.8428 23.4548 23.7618 28.6875 17.4941 28.6875C11.2264 28.6875 6.14538 23.4548 6.14538 17C6.14538 10.5452 11.2264 5.3125 17.4941 5.3125ZM30.9062 17C30.9062 9.37157 24.9014 3.1875 17.4941 3.1875C10.0868 3.1875 4.08198 9.37157 4.08198 17C4.08198 24.6284 10.0868 30.8125 17.4941 30.8125C24.9014 30.8125 30.9062 24.6284 30.9062 17Z"/>
                  <path fill={currentIndex === 0 ? '#A3A3A3' : '#E85112'} fillRule="evenodd" clipRule="evenodd" d="M19.3279 11.508C19.7091 11.9442 19.6748 12.616 19.2512 13.0085L14.9448 17L19.2512 20.9915C19.6748 21.3841 19.7091 22.0559 19.3279 22.492C18.9468 22.9282 18.2944 22.9636 17.8709 22.571L12.7124 17.7898C12.495 17.5883 12.3709 17.3012 12.3709 17C12.3709 16.6988 12.495 16.4118 12.7124 16.2103L17.8709 11.429C18.2944 11.0365 18.9468 11.0718 19.3279 11.508Z"/>
                </svg>
                <svg onClick={handleNext} className={`${currentIndex === items.length - 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`} xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="3 3 28 28" fill="none">
                  <path fill={currentIndex === items.length - 1 ? '#A3A3A3' : '#E85112'} fillRule="evenodd" clipRule="evenodd" d="M17.4941 5.3125C23.7618 5.3125 28.8428 10.5452 28.8428 17C28.8428 23.4548 23.7618 28.6875 17.4941 28.6875C11.2264 28.6875 6.14538 23.4548 6.14538 17C6.14538 10.5452 11.2264 5.3125 17.4941 5.3125ZM30.9062 17C30.9062 9.37157 24.9014 3.1875 17.4941 3.1875C10.0868 3.1875 4.08198 9.37157 4.08198 17C4.08198 24.6284 10.0868 30.8125 17.4941 30.8125C24.9014 30.8125 30.9062 24.6284 30.9062 17Z"/>
                  <path fill={currentIndex === items.length - 1 ? '#A3A3A3' : '#E85112'} fillRule="evenodd" clipRule="evenodd" d="M14.6621 11.508C14.2809 11.9442 14.3152 12.616 14.7388 13.0085L19.0452 17L14.7388 20.9915C14.3152 21.3841 14.2809 22.0559 14.6621 22.492C15.0432 22.9282 15.6956 22.9636 16.1191 22.571L21.2776 17.7898C21.495 17.5883 21.6191 17.3012 21.6191 17C21.6191 16.6988 21.495 16.4118 21.2776 16.2103L16.1191 11.429C15.6956 11.0365 15.0432 11.0718 14.6621 11.508Z"/>
                </svg>
              </div>
            </div>
            <div id='rdSecMob' ref={rdRefMob} className='h-fit w-full pt-[5vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.75vw]'>
                <div className='w-fit py-[2vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[3.25vw] tracking-wider font-semibold'>{t.ruraldev}</div>
                <div className='flex items-center rounded-l-full h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[14vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[7.5vw] py-[3.5vw] pb-[4vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[5vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[4.5vw] opacity-65 leading-[10vw] px-[5vw]'>
                  {t.rdvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full pt-[7.5vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <img className='w-full rounded-[2vw]' src={s2}/>
            </div>
            <div className='h-fit w-full pt-[7.5vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col justify-between items-center w-full mt-[0vw] p-[5vw] rounded-[1.5vw] border border-cor shadow-cs2 bg-black bg-opacity-[0.03]'>
                <div className='flex flex-row justify-between items-center text-[4.75vw] font-poppinsSemiBold'>{t.keyfocus}</div>
              </div>
              <div className={`h-[50vw] min-h-[50vw] w-full mt-[7.5vw] pb-[0.2vw] border-x border-cor rounded-[1vw] flex flex-row overflow-x-auto snap-x snap-mandatory scroll-smooth pointer-events-none touch-none ${classes.noScroll}`} ref={carouselRefMob}>
                <div className='h-full w-[90.35vw] flex flex-col justify-start items-start border-y border-cor shadow-cs2 rounded-[1vw] px-[5vw] pt-[3vw] pb-[2.5vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[3.8vw] font-poppinsSemiBold'>{t.primaryed}</div>
                  <div className='flex flex-row justify-start items-center text-[3.25vw] font-poppinsMedium leading-[6vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium'>
                      <li>{t.primaryed1}</li>
                      <li>{t.primaryed2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[90.35vw] flex flex-col justify-start items-start border-y border-cor shadow-cs2 rounded-[1vw] px-[5vw] pt-[3vw] pb-[2.5vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[3.8vw] font-poppinsSemiBold'>{t.agriculturaladv}</div>
                  <div className='flex flex-row justify-start items-center text-[3.25vw] font-poppinsMedium leading-[6vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium'>
                      <li>{t.agriculturaladv1}</li>
                      <li>{t.agriculturaladv2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[90.35vw] flex flex-col justify-start items-start border-y border-cor shadow-cs2 rounded-[1vw] px-[5vw] pt-[3vw] pb-[2.5vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[3.8vw] font-poppinsSemiBold'>{t.digitalfinance}</div>
                  <div className='flex flex-row justify-start items-center text-[3.25vw] font-poppinsMedium leading-[6vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start font-poppinsMedium'>
                      <li>{t.digitalfinance1}</li>
                      <li>{t.digitalfinance2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[90.35vw] flex flex-col justify-start items-start border-y border-cor shadow-cs2 rounded-[1vw] px-[5vw] pt-[3vw] pb-[2.5vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[3.8vw] font-poppinsSemiBold'>{t.literacy}</div>
                  <div className='flex flex-row justify-start items-center text-[3.25vw] font-poppinsMedium leading-[6vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.literacy1}</li>
                      <li>{t.literacy2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[90.35vw] flex flex-col justify-start items-start border-y border-cor shadow-cs2 rounded-[1vw] px-[5vw] pt-[3vw] pb-[2.5vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[3.8vw] font-poppinsSemiBold'>{t.infrastructure}</div>
                  <div className='flex flex-col justify-start items-center text-[3.25vw] font-poppinsMedium leading-[6vw]'>
                    {t.infrastructure1}
                    <ul className='list-outside list-disc ml-[-4vw] text-start'>
                      <li>{t.infrastructure2}</li>
                      <li>{t.infrastructure3}</li>
                      <li>{t.infrastructure4}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[90.35vw] flex flex-col justify-start items-start border-y border-cor shadow-cs2 rounded-[1vw] px-[5vw] pt-[3vw] pb-[2.5vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[3.8vw] font-poppinsSemiBold'>{t.scientific}</div>
                  <div className='flex flex-row justify-start items-center text-[3.25vw] font-poppinsMedium leading-[6vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.scientific1}</li>
                      <li>{t.scientific2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[90.35vw] flex flex-col justify-start items-start border-y border-cor shadow-cs2 rounded-[1vw] px-[5vw] pt-[3vw] pb-[2.5vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[3.8vw] font-poppinsSemiBold'>{t.health}</div>
                  <div className='flex flex-row justify-start items-center text-[3.25vw] font-poppinsMedium leading-[6vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.health1}</li>
                      <li>{t.health2}</li>
                      <li>{t.health3}</li>
                      <li>{t.health4}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[90.35vw] flex flex-col justify-start items-start border-y border-cor shadow-cs2 rounded-[1vw] px-[5vw] pt-[3vw] pb-[2.5vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-start text-[3.8vw] font-poppinsSemiBold'>{t.jobcreation}</div>
                  <div className='flex flex-row justify-start items-center text-[3.25vw] font-poppinsMedium leading-[6vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.jobcreation1}</li>
                      <li>{t.jobcreation2}</li>
                    </ul>
                  </div>
                </div>
                <div className='h-full w-[90.35vw] flex flex-col justify-start items-start border-y border-cor shadow-cs2 rounded-[1vw] px-[5vw] pt-[3vw] pb-[2.5vw] gap-[1.25vw] box-border flex-shrink-0'>
                  <div className='flex flex-row justify-start items-center text-[3.8vw] font-poppinsSemiBold'>{t.farmers}</div>
                  <div className='flex flex-row justify-start items-center text-[3.25vw] font-poppinsMedium leading-[6vw]'>
                    <ul className='list-outside list-disc ml-[2vw] text-start'>
                      <li>{t.farmers1}</li>
                      <li>{t.farmers2}</li>
                      <li>{t.farmers3}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="dots-container flex justify-center mt-4">
                {[...Array(9)].map((_, index) => (
                  <button
                    key={index}
                    ref={(el) => (dotRefsMob.current[index] = el)}
                    className="dot w-[3.5vw] h-[3.5vw] rounded-full mx-[1.75vw] my-[1vw] bg-gray-200"
                    onClick={() => scrollToGroupMob(index)}
                  />
                ))}
              </div>
            </div>
            <div id='envSecMob' ref={envRefMob} className='h-fit w-full pt-[4.25vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.75vw]'>
                <div className='w-fit py-[2vw] pl-[2.5vw] pr-[3.5vw] text-start text-white text-[3.25vw] tracking-wider font-semibold'>{t.sustainability}</div>
                <div className='flex items-center rounded-l-full h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[14vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[7.5vw] py-[3.5vw] pb-[4vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[5vw] font-semibold '>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[4.5vw] opacity-65 leading-[10vw] px-[5vw]'>
                  {t.sustainabilityvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col-reverse justify-between items-center w-full mt-[7.5vw] p-[3vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs4 transition duration-200'>
                <div className='w-full flex flex-col items-start justify-center text-start text-[3.5vw] leading-[7.5vw] py-[5vw]'>
                  <span className='text-[5vw] font-poppinsSemiBold pb-[2.5vw]'>{t.initiatives}</span>
                  <span className='text-[4vw] font-poppinsSemiBold'>{t.greendrives}</span>
                  <ul className='list-outside list-disc ml-[5vw] text-justify font-poppins'>
                    <li>{t.greendrives1}</li>
                    <li>{t.greendrives2}</li>
                    <li>{t.greendrives3}</li>
                  </ul>

                  <span className='text-[4vw] font-poppinsSemiBold pt-[5vw] pb-[2.5vw]'>{t.waterconservation}</span>
                  <ul className='list-outside list-disc ml-[5vw] text-justify font-poppins'>
                    <li>{t.waterconservation1}</li>
                    <li>{t.waterconservation2}</li>
                  </ul>
                </div>
                <img className='w-full' src={s3}/>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='mt-[7.5vw] relative'>
                <img className='w-full h-[60vw] rounded-[1.5vw]' src={tree}/>
                <div className='bg-black h-full w-full absolute top-0 opacity-20 rounded-[1.5vw]'></div>
                <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-between items-center w-[77vw] p-[3vw] pl-0 gap-[1.25vw] rounded-[1.5vw] shadow-cs2 bg-white bg-opacity-[0.8]'>
                  <div className='flex flex-row justify-between items-center text-[4vw] font-poppinsSemiBold'>{t.achievement}</div>
                  <div className='flex flex-row justify-between items-center'>
                    <ul className='list-outside list-disc ml-[7.5vw] text-start text-[3.5vw] leading-[4.5vw]'>
                      <li>{t.achievement1}</li>
                      <li>{t.achievement2}</li>
                      <li>{t.achievement3}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id='ythSecMob' ref={ythRefMob} className='h-fit w-full pt-[7.5vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.75vw]'>
                <div className='w-fit py-[2vw] pl-[2.5vw] pr-[15vw] text-start text-white text-[3.25vw] tracking-wider font-semibold'>{t.youthdev}</div>
                <div className='flex items-center rounded-l-full h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[14vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[7.5vw] py-[3.5vw] pb-[4vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[5vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[4.5vw] opacity-65 leading-[10vw] px-[5vw]'>
                  {t.youthvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col-reverse justify-between items-center w-full mt-[7.5vw] p-[3vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs3 transition duration-200'>
                <div className='w-full flex flex-col items-start justify-center text-start text-[3.5vw] leading-[7.5vw] py-[5vw]'>
                  <span className='text-[5vw] font-poppinsSemiBold pb-[2.5vw]'>{t.initiatives}</span>
                  <span className='text-[4vw] font-poppinsSemiBold'>{t.edsupp}</span>
                  <ul className='list-outside list-disc ml-[5vw] text-justify font-poppins'>
                    <li>{t.edsupp1}</li>
                    <li>{t.edsupp2}</li>
                  </ul>
                  <span className='text-[4vw] font-poppinsSemiBold pt-[5vw] pb-[2.5vw]'>{t.skilldev}</span>
                  <ul className='list-outside list-disc ml-[5vw] text-justify font-poppins'>
                    <li>{t.skilldev1}</li>
                    <li>{t.skilldev2}</li>
                  </ul>
                  <span className='text-[4vw] font-poppinsSemiBold pt-[5vw] pb-[2.5vw]'>{t.community}</span>
                  <ul className='list-outside list-disc ml-[5vw] text-justify font-poppins'>
                    <li>{t.community1}</li>
                    <li>{t.community2}</li>
                  </ul>
                </div>
                <img className='w-full' src={s4}/>
              </div>
            </div>
            <div id='fwSecMob' ref={fwRefMob} className='h-fit w-full pt-[7.5vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.75vw]'>
                <div className='w-fit py-[2vw] pl-[2.5vw] pr-[15vw] text-start text-white text-[3.25vw] tracking-wider font-semibold'>{t.farmersemp}</div>
                <div className='flex items-center rounded-l-full h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[14vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[7.5vw] py-[3.5vw] pb-[4vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[5vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[4.5vw] opacity-65 leading-[10vw] px-[5vw]'>
                  {t.farmervision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col-reverse justify-between items-center w-full mt-[7.5vw] p-[3vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs3 transition duration-200'>
                <div className='w-full flex flex-col items-start justify-center text-justify text-[3.5vw] leading-[7.5vw] py-[5vw]'>
                  <span className='text-[4vw] font-poppinsSemiBold pb-[2.5vw]'>{t.farmerinitiatives}</span>
                  <ul className='list-outside list-disc ml-[5vw] text-justify font-poppins'>
                    <li><span className='font-poppinsSemiBold'>{t.seminar}</span>{t.seminardesc}</li>
                    <li><span className='font-poppinsSemiBold'>{t.organic}</span>{t.organicdesc}</li>
                    <li><span className='font-poppinsSemiBold'>{t.rights}</span>{t.rightsdesc}</li>
                    <li><span className='font-poppinsSemiBold'>{t.growth}</span>{t.growthdesc}</li>
                  </ul>
                </div>
                <img className='w-full' src={s5}/>
              </div>
            </div>
            <div id='wSecMob' ref={womenRefMob} className='h-fit w-full pt-[7.5vw] px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-row justify-between items-center bg-cor w-full rounded-[1.75vw]'>
                <div className='w-fit py-[2vw] pl-[2.5vw] pr-[15vw] text-start text-white text-[3.25vw] tracking-wider font-semibold'>{t.womenemp}</div>
                <div className='flex items-center rounded-l-full h-full flex-grow px-[2.5vw] pl-0 bg-white'>
                  <img className='h-[14vw]' src={sun}/>
                </div>
              </div>
              <div className='flex flex-col justify-between items-center w-full mt-[7.5vw] py-[3.5vw] pb-[4vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs4 bg-black bg-opacity-[0.07]'>
                <div className='flex flex-row justify-between items-center italic font-poppinsMedium text-cor text-[5vw] font-semibold'>{t.vision}</div>
                <div className='flex flex-row justify-between items-center italic font-garamondItalic text-[4.5vw] opacity-65 leading-[10vw] px-[5vw]'>
                  {t.womenvision}
                </div>
              </div>
            </div>
            <div className='h-fit w-full px-[4.8vw] flex flex-col justify-between items-center'>
              <div className='flex flex-col-reverse justify-between items-center w-full mt-[7.5vw] p-[3vw] gap-[2vw] rounded-[2vw] border border-cor shadow-cs4 transition duration-200'>
                <div className='w-full flex flex-col items-start justify-center text-start text-[3.5vw] leading-[7.5vw] py-[5vw]'>
                  <span className='text-[5vw] font-poppinsSemiBold pb-[2.5vw]'>{t.initiatives}</span>
                  <span className='text-[4vw] font-poppinsSemiBold'>{t.anganwadi}</span>
                  <ul className='list-outside list-disc ml-[5vw] text-justify font-poppins'>
                    <li>{t.anganwadi1}</li>
                    <li>{t.anganwadi2}</li>
                  </ul>

                  <span className='text-[4vw] font-poppinsSemiBold pt-[5vw] pb-[2.5vw]'>{t.selfhelpgroups}</span>
                  <ul className='list-outside list-disc ml-[5vw] text-justify font-poppins'>
                    <li>{t.selfhelpgroups1}</li>
                    <li>{t.selfhelpgroups2}</li>
                  </ul>
                </div>
                <img className='w-full' src={s6}/>
              </div>
            </div>
          </div>
          < Footer/>
        </AnimatePresence>
    </div>
  )
}

export default Social