import React, { useState, useEffect } from "react";
import styles from "./coverevent.module.css";
import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";
import { useLanguage } from '../context/LanguageContext';
import translations from '../components/translation.js';


const CoverEvent = () => {
  const { language } = useLanguage();
  const t = translations[language];
  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  const [coverEvents, setCoverEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    let filtered = coverEvents;

    // Filter by date range
    if (fromDate && toDate) {
      filtered = filtered.filter((event) => {
        const eventDate = new Date(event.date);
        return (
          eventDate >= new Date(fromDate) && eventDate <= new Date(toDate)
        );
      });
    }

    // Filter by name or date from the search input
    if (searchTerm) {
      filtered = filtered.filter((event) => {
        const eventDate = new Date(event.date).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        return (
          event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          eventDate.includes(searchTerm)
        );
      });
    }

    setFilteredEvents(filtered);
  };

  useEffect(() => {
    const fetchCoverEvents = async () => {
      try {
        const response = await fetch(
          "https://www.meghnabordikar.com/upload/api/coverevents"
        );
        const data = await response.json();
        setCoverEvents(data); // Initialize coverEvents
        setFilteredEvents(data); // Initialize filteredEvents
      } catch (error) {
        console.error("Error fetching cover events:", error);
      }
    };
    fetchCoverEvents();
  }, []);

  const handleClick = (name, date) => {
    window.location.href = `/events?name=${encodeURIComponent(
      name
    )}&date=${encodeURIComponent(date)}`;
  };

  return (
    <div className={styles.outer}>
      <Header />
      <div className={styles.container}>
        <div className={styles.overlayText}>
          <div className={styles.eventhead}>
{t.event1}          </div>
          <div className={styles.eventhead2}>{t.event2}  </div>
          <div className={styles.outersearch}>
            <div className={styles.searchContainer}>
              <input
                type="text"
                placeholder={t.event_placeholder}
                className={styles.inputField}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className={styles.searchButton} onClick={handleSearch}>
              {t.event_search}

              </button>
            </div>
            <div className={styles.mobilesearchcon}>
            <input
                type="text"
                placeholder="Type Event Name or Date[like 1 January]"
                className={styles.inputField}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className={styles.searchButton} onClick={handleSearch}>
                {t.event_search}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.nextcontainer}>
        <div className={styles.sec1}>
          <div className={styles.datecontainer}>
            <h2 className={styles.heading}>{t.event_searchtag}</h2>
            <div className={styles.inputGroup}>
              <label>
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder="From Date"
                  className={styles.input}
                />
              </label>
            </div>
            <div className={styles.inputGroup}>
              <label>
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder="To Date"
                  className={styles.input}
                />
              </label>
            </div>
            <button onClick={handleSearch} className={styles.button}>
            {t.event_search}

            </button>
          </div>
        </div>
        <div
          style={{
            
          }}
          className={styles.con2}
        >
          {filteredEvents.map((event) => (
            <div className={styles.descimage}
              key={event._id}
              onClick={() => handleClick(event.name, event.date)}
              style={{
                cursor: "pointer",
                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                borderRadius: "8px",
                overflow: "hidden",
                textAlign: "center",
              }}
            >
              <img
                src={`https://www.meghnabordikar.com/${event.path}`} // Assuming event.path contains the relative image URL
                alt={event.name}
                style={{ width: "100%", height: "250px", objectFit: "cover" }}
              />
              <p
                style={{
                  fontSize: "16px",
                  color: "#FF5722",
                  margin: "8px 0",
                }}
              >
                {new Date(event.date).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CoverEvent;
