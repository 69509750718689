import React, { useEffect } from "react";
import styles from "./contact.module.css"; // CSS Module
import client from "../images/contact.png";
import facebook from "../images/Facebook.png";
import instagram from "../images/Instagram.png";
import twitter from "../images/Twitter.png";
import youtube from "../images/YouTube.png";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Header from '../components/Header/Header.js';
import Footer from '../components/Footer/Footer.js'
import { useLanguage } from '../context/LanguageContext';
import translations from '../components/translation.js';
const Contact = () => {
  const { language } = useLanguage();
  const t = translations[language];
  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

//   const navigate = useNavigate();
//   const navigatePath = (path) => {
//     navigate(path);
//   };
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     message: "",
//   });

//   // Handle form inputs
//   const handleInputChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault(); // Prevent form from refreshing the page

//     try {
//       // Send POST request to the backend
//       const response = await axios.post(
//         "https://dermo.iterve.ai/contactdetails/submit-form",
//         formData
//       );
//       console.log(response.data); // Log response from the server
//       setFormData({
//         name: "",
//         email: "",
//         phone: "",
//         message: "",
//       });
//     } catch (error) {
//       console.error("Error sending form data:", error);
//     }
//   };

  return (
    <div className={styles.outer}>
      <Header/>
      <div className={styles.container}>
        <div className={styles.imageSection}>
          <img src={client} alt="client" className={styles.client} />
        </div>
        <div className={styles.formContainer}>
          <h2 className={styles.title}>{t.ctitle}</h2>
          <p className={styles.subtitle}>
           {t.csubtitle}          </p>
          <form className={styles.form}>
            <input
              type="text"
              name="name"
              placeholder={t.namePlaceholder}
              className={styles.input}
            //   value={formData.name}
            //   onChange={handleInputChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder={t.emailPlaceholder}
              className={styles.input}
            //   value={formData.email}
            //   onChange={handleInputChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder={t.phonePlaceholder}
              className={styles.input}
            //   value={formData.phone}
            //   onChange={handleInputChange}
              required
            />
            <textarea
              name="message"
              placeholder={t.messagePlaceholder}
              className={styles.textarea}
            //   value={formData.message}
            //   onChange={handleInputChange}
              required
            />
            <button type="submit" className={styles.button}>
              {t.SendMessage}
            </button>
          </form>
        </div>
      </div>
      <div className={styles.container2}>
        <div className={styles.head1}>{t.c_tag}</div>
        <div className={styles.head2}>
{t.c_tag2}
        </div>
        <div className={styles.head3}>
{t.c_tag3}        </div>
        {/* Contact Information */}
        <div className={styles.contactItem}>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.ficon} />
          <div className={styles.location}>
          {t.c_loc}
          </div>
        </div>
        
        <div className={styles.contactItem} >
          <FontAwesomeIcon icon={faEnvelope} className={styles.ficon} />
          <div className={styles.location}>meghana.bordikar@gmail.com</div>
        </div>
        <div className={styles.contactItem}>
          <div className={styles.location}>{t.c_connect}</div>
          <div className={styles.socialmedia}>
            <div className={styles.socialIcons}>
              <a
                href="https://www.facebook.com/share/k7dRVumPPVobTUG7/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" className={styles.icon} />
              </a>
              <a
                href="https://www.instagram.com/meghnabordikar/profilecard/?igsh=MXJxYzJqZnZ4ZWVmOA=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Instagram" className={styles.icon} />
              </a>
              <a
                href="https://youtube.com/@mlameghnabordikardidi?si=RPE6Veo_Jy4hi8t2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="YouTube" className={styles.icon} />
              </a>
              <a
                href="https://x.com/MeghnaBordikar?t=IaPNJd19XTgT4PBpPTxBiA&s=08"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Twitter" className={styles.icon} />
              </a>
            </div>
          </div>
        </div>
      </div>
      
<Footer/>
    </div>
  );
};

export default Contact;
